import {
  CircularProgress,
  LinearProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  textResponsive: {
    [theme.breakpoints.up("md")]: {
      fontSize: "1.1rem !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem !important",
    },
  },
}));

export const LoadingScreen = (props) => {
  const { message } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress />
      <Typography
        variant="subtitle2"
        color="textPrimary"
        style={{ fontWeight: "600" }}
        className={classes.textResponsive}
      >
        {message ?? "Loading..."}
      </Typography>
    </div>
  );
};
