import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Typography, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const top100Films = [
  { label: "Option_1", value: 1 },
  { label: "Option_2", value: 2 },
  { label: "Option_3", value: 3 },
];

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormHelperText-contained": {
      margin: 0,
      marginTop: 4,
      color: "red",
    },
  },
}));

export function MuiSelect(props) {
  const classes = useStyles(props);
  // const { groupBy } = props;
  const getLabel = (props) => {
    return (
      <Typography
        variant="subtitle2"
        // color="textSecondary"
        // style={{ fontSize: 16 }}
        gutterBottom
      >
        {props.label}{" "}
        {props.isrequired && (
          <Typography variant="caption" style={{ color: "red" }}>
            *
          </Typography>
        )}
      </Typography>
    );
  };

  return (
    <div className={classes.root} id={"Txt +" + props?.id}>
      {getLabel(props)}
      <Autocomplete
        {...props}
        disabled={(props.isReadonly || props?.disabled) ?? false}
        multiple={props.multiple}
        value={props.multiple ? props.value : props.value}
        id="tags-outlined"
        options={props.option}
        getOptionLabel={props.getOptionLabel}
        defaultValue={props.defaultValue}
        filterSelectedOptions
        // groupBy={groupBy}
        onChange={(e, newValue) => props.handleselect(newValue)}
        size={props.size}
        renderInput={(params) => (
          <TextField
            {...params}
            // error={props.error}
            variant="outlined"
            // label={props.label}
            InputLabelProps={{
              shrink: false,
            }}
            required={props.isrequired}
            placeholder={props.placeholder}
            error={
              props.error
                ? props.error
                : props?.errorValidation && props?.errorValidation?.error
                ? props?.errorValidation?.error
                : false
            }
            helperText={
              props?.helperText
                ? props?.helperText
                : props?.errorValidation && props?.errorValidation?.errorMessage
                ? props?.errorValidation?.errorMessage
                : ""
            }
          />
        )}
      />
    </div>
  );
}

MuiSelect.propTypes = {
  isReadonly: PropTypes.bool,
  multiple: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  defaultValue: PropTypes.array,
  option: PropTypes.array, // EX: [{value: "", label: ""}],
  handleselect: PropTypes.func,
  size: PropTypes.oneOf(["small", "medium"]),
  error: PropTypes.bool,
  value: PropTypes.array, //{value: "", label: ""}
};

MuiSelect.defaultProps = {
  multiple: false,
  label: "",
  placeholder: "",
  defaultValue: [],
  option: top100Films,
  size: "small",
  handleselect: () => {},
  error: false,
  value: [],
};
