import React from "react";
import { makeStyles, Typography, Grid, Button } from "@material-ui/core";
import { TabComponent } from "../../componentsV2";
import {
  TableComponent,
  LoadingSection,
  InternatlServerError,
  NotFoundSection,
  AddColumn,
} from "../../components";
import { useLocation, useHistory } from "react-router-dom";
import { Message } from "../../utils";
import {
  DrawerContext,
  DialogContext,
  BackdropContext,
  AlertContext,
} from "../../contexts";
import { AlertProps, DrawerProps } from "../../utils/constants";
import { LineListSearchbar } from "../../componentsV2";
import { actions } from "surveillance-binder";
import { useDispatch } from "react-redux";

// Store Action's list
const { GET_ENTRIES_BY_FORM_ID } = actions;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  countStyle: {
    fontSize: 10,
  },
  avatar: {
    width: 20,
    height: 20,
    backgroundColor: theme.palette.secondary.main,
  },
  flexStyle: {
    display: "flex",
    alignItems: "center",
  },
}));

export const Entries = (props) => {
  const classes = useStyles(props);

  let { data = [], loading, error, initialLoading = false } = props;

  const history = useHistory();
  // Declaring Store dispatch
  const dispatch = useDispatch();
  const drawer = React.useContext(DrawerContext);
  const dialogContext = React.useContext(DialogContext);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);

  const query = new URLSearchParams(useLocation().search);

  let queryId = query.get("id");

  const statusUpdateFunc = (status, data) => {
    props.onStatusUpdate(status, data);
  };

  // const giveMeSelectedTab = () => {
  //   let queryTab = query.get("tab");

  //   if (!queryTab || parseInt(queryTab) >= tabs.length) {
  //     return 0;
  //   } else {
  //     return parseInt(queryTab);
  //   }
  // };

  // const getName = (name, count) => {
  //   return (
  //     <div className={classes.flexStyle}>
  //       <Typography style={{ marginRight: 5 }}>{name}</Typography>
  //       <Avatar className={classes.avatar}>
  //         <Typography variant="h1" className={classes.countStyle}>
  //           {count}
  //         </Typography>
  //       </Avatar>
  //     </div>
  //   );
  // };

  const onTabClick = (tab) => {
    props.getNotifiedEntryByFormId(data?.form_detail);
    history.push({
      search: `?id=${queryId}&tab=${tab}`,
    });
  };

  // const tabs = [
  //   {
  //     name: () => getName("Submitted", props?.data?.notified?.row?.length ?? 0),
  //     field: "submitted",
  //     icon: (isSelected) => <Investigated isSelected={isSelected} />,
  //   },
  //   {
  //     name: () => getName("Draft", props?.data?.draft?.row?.length ?? 0),
  //     field: "draft",
  //     icon: (isSelected) => <ForInvestigation isSelected={isSelected} />,
  //   },
  // ];

  const onColumnAdditionCompleted = () => {
    props.getNotifiedEntryByFormId(data?.form_detail);
    closeDrawer();
    onTabClick(0);
  };

  const onAddColumn = () => {
    drawer.setDrawer({
      ...drawer,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <AddColumn
          data={data?.form_detail?.column_list}
          source="notification"
          onColumnAdditionCompleted={onColumnAdditionCompleted}
        />
      ),
      onClose: () => closeDrawer(),
    });
  };

  const closeDrawer = () => {
    drawer.setDrawer({
      ...drawer,
      direction: DrawerProps.direction.right,
      open: false,
    });
  };

  const onValueChange = (value) => {
    let searchKeyValueString = "";
    value?.search &&
      value?.selectValue &&
      value?.selectValue.forEach((l, i) => {
        if (l?.ui_component === "mobile_no") {
          searchKeyValueString += `UPPER(newAnswer['${
            l.value
          }'].number) like '${value?.search.toUpperCase()}%' ${
            i === value?.selectValue?.length - 1 ? "" : " || "
          }`;
        } else {
          searchKeyValueString += `UPPER(newAnswer['${
            l.value
          }']) like '${value?.search.toUpperCase()}%' ${
            i === value?.selectValue?.length - 1 ? "" : " || "
          }`;
        }
      });

    let params = {
      formId: queryId,
      type: "notification",
      searchEntry: searchKeyValueString,
    };

    if (value?.search) {
      dispatch(GET_ENTRIES_BY_FORM_ID(params));
    } else if (!value?.search) {
      dispatch(GET_ENTRIES_BY_FORM_ID(params));
    }
  };

  const deleteEntry = (data) => {
    dialogContext.setDialog({
      ...dialogContext,
      open: true,
      title: Message.notification_delete_title,
      body: Message.notification_delete_message,
      positiveBtn: Message.notification_delete_btn,
      negativeBtn: "Cancel",
      onOk: () => deleteNotification(data),
    });
  };

  const deleteNotification = (data) => {
    dialogContext.setDialog({ ...dialogContext, open: false });
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: Message.notification_delete,
    });

    Promise.resolve(
      dispatch(
        actions.DELETE_ENTRY({
          entry_id: data.entry_id,
        })
      )
    ).then((res) => {
      backdrop.setBackDrop({ ...backdrop, open: false });
      onTabClick(1);
      let resStatus = res.payload.error;

      alert.setSnack({
        ...alert,
        open: true,
        severity: resStatus
          ? AlertProps.severity.error
          : AlertProps.severity.success,
        msg: resStatus
          ? "Notification deletion Failed, Try Again later!"
          : "Notification deleted Successfully !",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    });
  };
  return (
    <div className={classes.root}>
      <Grid
        className={classes.title}
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h5">{`${props?.entriesTitle} Entries`}</Typography>
        </Grid>

        <Grid item>
          <Button id={"addColumn"} onClick={onAddColumn} color="secondary">
            + Add Column
          </Button>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item style={{ width: "100%" }}>
          <LineListSearchbar
            data={data?.form_detail?.column_list}
            onValueChange={onValueChange}
            loading={initialLoading && loading}
          />
        </Grid>
      </Grid>

      {loading && initialLoading && !error && (
        <LoadingSection message={Message.notification_detail_fetching} />
      )}
      {!loading && error && !data?.form_detail?.form_name && (
        <InternatlServerError
          message={Message.notification_detail_not_found}
          onRetry={props.getNotifiedEntryByFormId}
        />
      )}
      {/* {!loading && !error && data?.form_detail?.form_name && data?.row?.length === 0 && <NotFoundSection message={Message.notification_detail_not_found} />} */}
      {!loading && !error && !data?.form_detail?.form_name && (
        <NotFoundSection />
      )}

      {data?.form_detail?.form_name && !initialLoading && (
        <div>
          <TabComponent
            padding={false}
            onTabClick={onTabClick}
            loading={loading}
            selected={1}
            tabtitles={[{ field: "submitted" }, { field: "draft" }]}
            // viewPortOverflow

            draft={
              <TableComponent
                hideShadow
                loading={loading}
                data={props?.data?.draft}
                allStatus={props?.data?.allStatus}
                notFoundMessage={"No Draft found"}
                onToogleSelected={statusUpdateFunc}
                deleteEntry={deleteEntry}
                height
              />
            }
            submitted={
              <TableComponent
                hideShadow
                loading={loading}
                data={props?.data?.notified}
                allStatus={props?.data?.allStatus}
                notFoundMessage={"No Notification found"}
                onToogleSelected={statusUpdateFunc}
                height
              />
            }
          />
        </div>
      )}
    </div>
  );
};
