import React, { useEffect } from "react";
import { makeStyles, Divider, LinearProgress } from "@material-ui/core";
import { SearchFilter, CardList, CardListLoader } from "../../componentsV2";
import { useHistory, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {
  InternatlServerError,
  NotFoundSection,
  FilterBuilder,
} from "../../components";
import { DrawerContext } from "../../contexts";
import { Message, DrawerProps } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  SearchFilter: {
    padding: theme.spacing(2),
  },
  viewPortHeight: {
    height: "calc(100vh - 285px)",
    overflowY: "auto",
  },
}));

export const FormList = (props) => {
  const classes = useStyles(props);
  const history = useHistory();

  const drawerContext = React.useContext(DrawerContext);

  const [activeForm, setActiveForm] = React.useState("");

  let { data = [], loading, error } = props;
  // ! Filter Only Data That Is permsnread Is TRUE
  data = data.filter(
    (v) =>
      jwt_decode(
        `eyJhbGciOiJIUzI1NiJ9.${localStorage.getItem(
          "permissionData"
        )}.FXibJVNHsvJ6Ff-N9XtTTom9cGExRqsldHbrhAOqRUg`
      ).filter(
        (e) =>
          e?.permissionRepo?.unique_id === `forms/${v.form_id}` &&
          e?.permsnread === true
      ).length !== 0
  );
  //Function Ends Here
  const query = new URLSearchParams(useLocation().search);

  let queryId = query.get("id");

  const onSearchChange = (value) => {
    if (value && value?.length % 2 === 0) {
      props.getNotificationForms(value);
    } else if (!value) {
      props.getNotificationForms();
    }
  };

  const onFilterClicked = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.left,
      variant: DrawerProps.variant.temporary,
      component: (
        <FilterBuilder
          title="Disease Filter"
          showClassification={true}
          classification={props?.classification}
          showIcd10={true}
          icd10={props?.icd10}
          applyFilter={applyFilter}
        />
      ),
      onClose: () => onDrawerClosedLeft(),
    });
  };

  const applyFilter = (data, isFilterApplied) => {
    onDrawerClosedLeft();
    props?.applyFilter(data, isFilterApplied);
  };

  const onDrawerClosedLeft = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: false,
      direction: DrawerProps.direction.left,
    });
  };

  const handleEntries = (data) => {
    props?.getNotifiedEntryByFormId(data);
    props?.onNewFormClicked(true);

    setActiveForm(data.form_id);

    history.push({
      search: `?id=${data.form_id}&tab=0`,
    });

    if (props?.closeDrawer) {
      props?.onDrawerClosedLeft();
    }
  };

  useEffect(() => {
    data?.length > 0 &&
      data?.map((form, key) => {
        if (!queryId && key === 0) {
          props?.getNotifiedEntryByFormId(form);
          setActiveForm(form.form_id);

          history.push({
            search: `?id=${form.form_id}&tab=0`,
          });
        } else if (queryId === form.form_id) {
          props?.getNotifiedEntryByFormId(form);
          setActiveForm(form.form_id);
        }
        return form;
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.SearchFilter}>
        <SearchFilter
          id={"notification"}
          title="Disease(s)"
          placeholder="Search form,disease or ICD-10"
          // loading={data?.length > 0 ? loading : false}
          onSearchChange={onSearchChange}
          onFilterClicked={onFilterClicked}
          isFilterApplied={props?.isFilterApplied}
        />
      </div>
      <Divider />
      {loading && <LinearProgress />}

      {loading && !error && data?.length === 0 && <CardListLoader count={10} />}
      {!loading && error && data?.length === 0 && (
        <InternatlServerError
          message={Message.notification_error}
          onRetry={props.getNotificationForms}
        />
      )}
      {!loading && !error && data?.length === 0 && (
        <NotFoundSection message={Message.notification_not_found} />
      )}

      {data?.length > 0 && (
        <div className={classes.viewPortHeight}>
          {data?.map((form, key) => {
            return (
              <CardList
                keys={key}
                id={form?.form_id}
                title={form?.form_name}
                count={form?.notification_count ?? 0}
                onCardClicked={() => handleEntries(form)}
                activeForm={activeForm}
                diseaseName={form?.mapped_diseases
                  ?.map((_) => _?.disease_name ?? "")
                  ?.toString()}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
