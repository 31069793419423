import React from "react";
import { Authorization } from "atp-authorization";
import { withNavBars } from "../../HOCs";
import { withTheme } from "@material-ui/core";

const AccessManagement = (props) => {
  return (
    <div>
      <Authorization theme={props?.theme} />
    </div>
  );
};

export default withNavBars(withTheme(AccessManagement));
