import React from "react";
import {
  Paper,
  Typography,
  Grid,
  makeStyles,
  Button,
  IconButton,
  Box,
} from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditIcon from "@material-ui/icons/Edit";
import { AddAddressInput } from "./addressDrawer";
import { DrawerContext } from "../../../../contexts";
import { DrawerProps } from "../../../../utils";

// import { AddAddressInput } from './addAddressInput';
const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  gridItem: {
    padding: theme.spacing(2),
    "& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button":
      {
        margin: 0,
        display: "none",
      },
  },
  Addresscard: {
    width: "100%",
    padding: "10px 0",
    backgroundColor: "#F9F9F9",
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
  },
  numbertag: {
    backgroundColor: "#000",
    padding: "5px 7px",
    borderRadius: "0 5px 5px 0",
    color: "#ffffff",
  },
  spacebtw: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 10px",
  },
  textResponsive: {
    // fontWeight: "600",
    fontSize: "1rem",
    flex: "1 1 auto",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem !important",
    },
  },
  heading: {
    margin: "8px 0px",
  },
}));

export const AddAddress = (props) => {
  const classes = useStyles(props);
  const { state = null, updateState = () => false, Use, editData } = props;
  const drawerContext = React.useContext(DrawerContext);
  const [address, setAddressList] = React.useState(state?.address ?? []);
  React.useEffect(() => {
    if (editData) {
      setAddressList(editData?.address);
    }
    // eslint-disable-next-line
  }, [editData]);
  const addAddress = (dataObj, edit, index) => {
    if (edit) {
      let updateList = address.map((l, i) =>
        i === index
          ? {
              id: address?.length + 1,
              type: dataObj?.type,
              address_line1: dataObj?.address_line1,
              address_line2: dataObj?.address_line2,
              address_line3: dataObj?.address_line3,
              city: dataObj?.city,
              district: dataObj?.district,
              state: dataObj?.state,
              country: dataObj?.country,
              pincode: dataObj?.pincode,
              google_location_data: dataObj?.google_location_data,
            }
          : l
      );
      setAddressList(updateList);
      updateState("address", updateList);

      onDrawerClosed();
    } else {
      let updateList = [
        ...address,
        {
          id: address?.length + 1,
          type: dataObj?.type,
          address_line1: dataObj?.address_line1,
          address_line2: dataObj?.address_line2,
          address_line3: dataObj?.address_line3,
          city: dataObj?.city,
          district: dataObj?.district,
          state: dataObj?.state,
          country: dataObj?.country,
          pincode: dataObj?.pincode,
          google_location_data: dataObj?.google_location_data,
        },
      ];
      setAddressList(updateList);
      updateState("address", updateList);

      onDrawerClosed();
    }
  };

  const deleteIcon = (id) => {
    let Updatelist = address.filter((item) => item.id !== id);
    setAddressList(Updatelist);
    updateState("address", Updatelist);
  };

  const onAddAddressClicked = (isEdit, data, index) => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <AddAddressInput
          addAddress={addAddress}
          isEdit={isEdit}
          editData={data}
          onDrawerClosed={onDrawerClosed}
          Use={Use}
          index={index}
        />
      ),
      onClose: () => onDrawerClosed(),
    });
  };

  const onDrawerClosed = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      direction: DrawerProps.direction.right,
      open: false,
    });
  };

  let cardaddress = address?.map((item, index) => (
    <Grid
      key={item.id}
      container
      direction="row"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={12} sm={12} lg={12} className={classes.gridItem}>
        <div className={classes.Addresscard}>
          <div className={classes.numbertag}>
            <Typography variant="body1">{item?.id}</Typography>
          </div>

          <div className={classes.spacebtw}>
            <div>
              <Typography variant="body1">{item?.use?.label}</Typography>
              <Typography variant="caption">
                {`${item?.address_line1 ?? ""} - ${
                  item?.address_line2 ?? ""
                }- ${item?.address_line3 ?? ""}- ${item?.city ?? ""}- ${
                  item?.district ?? ""
                }- ${item?.state ?? ""}- ${item?.country ?? ""}- ${
                  item?.pincode ?? ""
                }`}
              </Typography>
            </div>
            <div>
              <IconButton
                id={"editBtn"}
                onClick={() => onAddAddressClicked(true, item, index)}
              >
                <EditIcon />
              </IconButton>
              <IconButton id={"deleteBtn"} onClick={() => deleteIcon(item?.id)}>
                <DeleteOutlineOutlinedIcon color="error" />
              </IconButton>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  ));

  return (
    <>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box className={classes.heading}>
          <Typography variant="body1" className={classes.textResponsive}>
            Address
            <span style={{ color: "red", marginLeft: "2px" }}>*</span>
          </Typography>
        </Box>
        <Button
          id={"Button1T6"}
          variant="text"
          color="primary"
          onClick={() => onAddAddressClicked(false, null)}
        >
          + Add New Address
        </Button>
      </div>
      {address?.length !== 0 ? (
        cardaddress
      ) : (
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item xs={12} sm={12} lg={12} className={classes.gridItem}>
            <Typography
              variant={"subtitle2"}
              color={"textSecondary"}
              style={{ margin: "8px 0px" }}
            >
              Your are yet to add your address
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};
