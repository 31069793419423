import React from "react";
import { TextField } from "@material-ui/core";
import {
  Paper,
  Typography,
  Grid,
  makeStyles,
  Button,
  Divider,
  Switch,
} from "@material-ui/core";
import { Select, UploadReports } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { actions } from 'surveillance-binder';
//import MenuItem from '@material-ui/core/MenuItem';
//import FormControl from '@material-ui/core/FormControl';
const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  gridItem: {
    padding: theme.spacing(1),
    "& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button":
      {
        margin: 0,
        display: "none",
      },
  },
}));

export const FacilityDetails = ({
  state = {},
  updateState = () => false,
  isEdit = false,
  client_id = null,
}) => {
  // Declaring Store dispatch
  const dispatch = useDispatch();

  const orgLevelCareData = useSelector(
    (state) => state?.organizationSlice?.orgLevelCare
  );
  const orgParEntityData = useSelector(
    (state) => state?.organizationSlice?.orgParEntity
  );
  const organizationEntityTypeData = useSelector(
    (state) => state?.organizationSlice?.organizationEntityType
  );

  // console.log(state, "state");

  const classes = useStyles();

  React.useEffect(() => {
    if (
      !Array.isArray(state.imageDetails) &&
      state?.imageDetails?.file === undefined
    ) {
      updateState("imageDetails", []);
    }
    // eslint-disable-next-line
  }, [state.imageDetails]);

  const updateFacility = async (name, value) => {
    if (value?.label === "Organization") {
      updateState(name, value);
      await dispatch(
        actions.ORGANIZATION_GET_PARENT_ENTITY({
          type: process.env.REACT_APP_Enterprice_Id,
          client_id,
        })
      );
    } else if (value?.label === "Facility") {
      updateState(name, value);
      await dispatch(
        actions.ORGANIZATION_GET_PARENT_ENTITY({
          type: process.env.REACT_APP_ORGANIZATION_Id,
          client_id,
        })
      );
    } else {
      updateState(name, value);
    }
  };

  return (
    <div style={{ padding: "8px", width: "100%", display: "flex" }}>
      <Paper
        style={{
          width: "20%",
          textAlign: "center",
          marginRight: "10px",
          boxSizing: "border-box",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          "box-shadow": "0px 10px 25px #0000000A",
          "border-radius": "8px",
          opacity: 1,
        }}
      >
        <div>
          {state?.imageDetails?.length <= 0 && (
            <>
              <UploadReports
                onChange={(newFValue) => updateState("imageDetails", newFValue)}
                value={state.imageDetails}
                single={true}
                hide={true}
              />
            </>
          )}

          {!Array.isArray(state.imageDetails) &&
            state?.imageDetails?.file === undefined && (
              <>
                <UploadReports
                  onChange={(newFValue) =>
                    updateState("imageDetails", newFValue)
                  }
                  value={state.imageDetails}
                  single={true}
                  hide={true}
                />
              </>
            )}

          {Object.keys(state?.imageDetails)?.length > 0 &&
            !Array.isArray(state?.imageDetails) &&
            typeof isEdit === "string" && (
              <>
                {state.imageDetails?.file && (
                  <img
                    src={state.imageDetails?.file}
                    alt="logo"
                    style={{
                      height: "230px",
                      width: "230px",
                      "object-fit": "contain",
                    }}
                  />
                )}
              </>
            )}

          {state?.imageDetails?.length > 0 && typeof isEdit === "string" && (
            <>
              <img
                src={state.imageDetails[0]?.file}
                alt="logo"
                style={{
                  height: "230px",
                  width: "230px",
                  "object-fit": "contain",
                }}
              />
            </>
          )}

          {state?.imageDetails?.length > 0 && typeof isEdit === "boolean" && (
            <>
              <img
                src={state.imageDetails[0]?.file}
                alt="logo"
                style={{
                  height: "230px",
                  width: "230px",
                  "object-fit": "contain",
                }}
              />
            </>
          )}
        </div>

        {state?.imageDetails?.length !== 0 && (
          <>
            <Divider />
            <Button
              variant="outlined"
              size="large"
              style={{ padding: "8px", margin: "8px" }}
              onClick={() => {
                updateState("imageDetails", []);
              }}
            >
              Delete Image
            </Button>
          </>
        )}
      </Paper>

      <Paper
        style={{
          width: "80%",
          padding: "8px 0",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          "box-shadow": "0px 10px 25px #0000000A",
          "border-radius": "8px",
          opacity: 1,
        }}
      >
        <Grid container direction="row" display="flex" alignItems="center">
          <Grid item xs={12} sm={12} lg={12} style={{ padding: "0 16px" }}>
            <Typography gutterBottom variant="body1">
              Facility Details
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} className={classes.gridItem}>
            <Select
              id="FacilityType"
              className="basic-single"
              label={"Facility Type"}
              isReadonly={isEdit ? true : false}
              value={[state.EntityType]}
              handleselect={(value) => updateFacility("EntityType", value)}
              placeholder="Select"
              option={organizationEntityTypeData?.data}
              isClearable={true}
              isrequired={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3} className={classes.gridItem}>
            <div style={{ display: "flex" }}>
              <Typography gutterBottom variant="body1">
                Facility Code
              </Typography>
              <Typography
                variant="caption"
                style={{ color: "red", marginLeft: "2px" }}
              >
                *
              </Typography>
            </div>

            <TextField
              id="FacilityCode"
              variant="outlined"
              size="small"
              placeholder={"APHI"}
              value={state?.EntityCode}
              onChange={(e) => updateState("EntityCode", e.target.value)}
              fullWidth
              margin="none"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6} className={classes.gridItem}>
            <div style={{ display: "flex" }}>
              <Typography gutterBottom variant="body1">
                Facility Name
              </Typography>
              <Typography
                variant="caption"
                style={{ color: "red", marginLeft: "2px" }}
              >
                *
              </Typography>
            </div>

            <TextField
              id="FacilityName"
              variant="outlined"
              size="small"
              value={state?.EntityName}
              onChange={(e) => updateState("EntityName", e.target.value)}
              placeholder={"AINQA Hospitals-India"}
              fullWidth
              margin="none"
            />
          </Grid>
          {state?.EntityType?.label !== "Enterprise" &&
            state?.EntityType?.value && (
              <Grid item xs={12} sm={6} lg={5} className={classes.gridItem}>
                <div style={{ display: "flex" }}>
                  <Typography gutterBottom variant="body1">
                    Parent Entity{" "}
                  </Typography>
                  <Typography
                    variant="caption"
                    style={{ color: "red", marginLeft: "2px" }}
                  >
                    *
                  </Typography>
                </div>

                <Select
                  id="ParentEnterprise"
                  variant="outlined"
                  size="small"
                  value={[state?.ParentEntity]}
                  handleselect={(value) => updateState("ParentEntity", value)}
                  placeholder={
                    state?.EntityType?.value
                      ? "AINQA Group of Hospitals"
                      : "Select Facility Type to enable Parent Enterprise"
                  }
                  fullWidth
                  margin="none"
                  option={orgParEntityData?.data}
                  disabled={state?.EntityType?.value ? false : true}
                />
              </Grid>
            )}
          {state?.EntityType?.label === "Enterprise" && (
            <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
              <Select
                id="LevelOfCare"
                className="basic-single"
                classNamePrefix="select"
                label={"Level Of Care"}
                value={[state?.LevelOfCare]}
                handleselect={(value) => updateState("LevelOfCare", value)}
                isLoading={orgLevelCareData?.loading}
                isClearable
                // isDisabled={!isAdmin}
                isSearchable
                placeholder="Teritiary Care"
                // option={[{value: "Facility", label: "Facility"},{value:"Organization",label:"Organization"},{value:"EnterPrise",label:"Enterprise"}]}
                option={orgLevelCareData?.data}
              ></Select>
            </Grid>
          )}
          <Grid item xs={12} sm={6} lg={6} className={classes.gridItem}>
            <Typography gutterBottom variant="body1">
              PCI Location
            </Typography>
            <Switch
              checked={state?.PCILocation ? true : false}
              onChange={(e) => updateState("PCILocation", e.target.checked)}
              name="PCILocation"
              color="primary"
              inputProps={{ "aria-label": "PCILocation checkbox" }}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
