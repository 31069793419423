import React from "react";
import { Paper, Grid, makeStyles, Typography } from "@material-ui/core";
import { CustomManageLevel } from "../../componentsV2";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    padding: "8px",
  },
  gridItem: {
    padding: theme.spacing(1),
    "& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button":
      {
        margin: 0,
        display: "none",
      },
  },
}));

export const ClientLevels = (props) => {
  const classes = useStyles();

  const [state, setState] = React.useState(false);

  const setLocationValue = (value) => {
    props.updateState("clientlevels", value);
  };

  React.useEffect(() => {
    if (!state) {
      setState(false);
    }
    // eslint-disable-next-line
  }, [props?.state?.clientlevels]);

  return (
    <>
      {
        <div className={classes.root}>
          <Paper
            style={{
              width: "100%",
              padding: "8px",
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              "box-shadow": "0px 2px 4px #0000000A",
              "border-radius": "8px",
              opacity: 1,
            }}
          >
            <Grid
              container
              style={{
                backgroundColor: "#fff",
                marginTop: "5px",
                padding: "16px",
              }}
            >
              <Grid item xs={12}>
                <Typography variant="body1">Client Levels</Typography>
              </Grid>

              <CustomManageLevel
                id={"dashBoardAdminLocation"}
                label={" "}
                showAs={"column"}
                value={props?.state?.clientlevels}
                handleChange={(values) => setLocationValue(values)}
                disabled={true}
                client_id={props?.client_id}
              />
            </Grid>
          </Paper>
        </div>
      }
    </>
  );
};
