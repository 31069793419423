/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-02-10
 * @modify date 2021-11-28
 * @desc Collection of constants
 */
 import React from "react";
 import { Routes } from "../router/routes";
 // import ListAltIcon from '@material-ui/icons/ListAlt';
 import AccountBalanceRoundedIcon from "@material-ui/icons/AccountBalanceRounded";
 import GeneralMaster from "@material-ui/icons/LanguageRounded";
 import Dashboard from "@material-ui/icons/DashboardRounded";
 import Organization from "@material-ui/icons/ApartmentRounded";
 import Notification from "@material-ui/icons/NotificationsActiveTwoTone";
 import Verifictaion from "@material-ui/icons/DomainRounded";
 import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
 import AssignmentRoundedIcon from "@material-ui/icons/AssignmentRounded";
 
 import { MdOutlineCoronavirus } from "react-icons/md";
 import SchemaIcon from "@mui/icons-material/Schema";
 import AssessmentIcon from "@material-ui/icons/Assessment";
 export const UserRoles = {
   role: "role",
 };
 
 /**
  * Object which has the proper name of all the role
  * used in the application.
  */
 export let UserRolesName = {
   role: "Role",
 };
 
 /**
  * Object which has the different themes used in
  * the application.
  */
 export let Themes = {
   default: "default",
   dark: "dark",
 };
 
 /**
  * Object which has the different props for the Alert Component (/src/component/alert)
  * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
  */
 export let AlertProps = {
   vertical: {
     top: "top",
     bottom: "bottom",
   },
   horizontal: {
     left: "left",
     right: "right",
     center: "center",
   },
   severity: {
     success: "success",
     error: "error",
     warning: "warning",
     info: "info",
   },
 };
 
 /**
  * Object which has the different props for the Drawer Component (/src/App.drawer.js)
  * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
  */
 export const DrawerProps = {
   direction: {
     top: "top",
     bottom: "bottom",
     left: "left",
     right: "right",
   },
   variant: {
     permanent: "permanent",
     persistent: "persistent",
     temporary: "temporary",
   },
 };
 
 /**
  * Object has the key and value pair of all the keys which
  * are used to store some values in the local storage.
  */
 export let LocalStorageKeys = {
   authToken: "auth_token",
   version: "version",
 };
 
 /**
  * Object has the key and value pair of all the HTTP method
  * used for an network call.
  */
 export let NetWorkCallMethods = {
   get: "GET",
   post: "POST",
   put: "PUT",
   delete: "DELETE",
   update: "UPDATE",
 };
 
 export const crud = {
   create: "create",
   read: "read",
   update: "update",
   delete: "delete",
 };
 
 export const NavBarArr = [
   {
     name: "Dashboard",
     pageId: "bmy90",
     page: Routes.dashboard,
     repo: "Dashboard",
     masterLayoutId: "o4jsfz",
     size: 0,
     icon: <Dashboard />,
     childPageSelect: [],
   },
   {
     name: "Access Management",
     pageId: "bmy90",
     page: Routes.AccessManagement,
     repo: "Access Management",
     masterLayoutId: "o4jsfz",
     size: 0,
     icon: <AccountBalanceRoundedIcon />,
     childPageSelect: [],
   },
   {
     name: "General Master",
     pageId: "bmy90",
     repo: "General Master",
     page: Routes.GeneralMaster,
     masterLayoutId: "o4jsfz",
     size: 0,
     icon: <GeneralMaster />,
     childPageSelect: [],
   },
   {
     name: "Manage Disease",
     pageId: "bmy90",
     repo: "Manage Disease",
     page: Routes.managedisease,
     masterLayoutId: "o4jsfz",
     size: 0,
     icon: <MdOutlineCoronavirus size={"1.5rem"} />,
     childPageSelect: [],
   },
   {
     name: "Manage Forms",
     pageId: "bmy90",
     page: Routes.manage_forms,
     repo: "Manage Forms",
     masterLayoutId: "o4jsfz",
     size: 0,
     icon: <AssignmentRoundedIcon />,
     childPageSelect: [],
   },
   // {
   //   name: "Manage Organization",
   //   pageId: "bmy90",
   //   page: Routes.Managefacility,
   //   repo: "Manage Organization",
   //   masterLayoutId: "o4jsfz",
   //   size: 0,
   //   icon: <Organization />,
   //   childPageSelect: [],
   // },
   {
     name: "Manage Client",
     pageId: "bmy90",
     page: Routes.Client,
     repo: "Manage Client",
     masterLayoutId: "o4jsfz",
     size: 0,
     icon: <Organization />,
     childPageSelect: [],
   },
   {
     name: "Notification Form",
     pageId: "bmy90",
     page: Routes.notify,
     repo: "Notification",
     masterLayoutId: "o4jfzd",
     size: 0,
     icon: <Notification />,
     childPageSelect: [],
   },
   // {
   //   name: "Notification Registry",
   //   pageId: "bmdy90",
   //   page: Routes.notifications,
   //   repo: "NotificationRegistry",
   //   masterLayoutId: "o4jfzsd",
   //   size: 0,
   //   icon: <Notification />,
   //   childPageSelect: [],
   // },
   {
     name: "Verification Form",
     pageId: "bm890",
     page: "",
     repo: "Verification Form",
     masterLayoutId: "o4jfzd",
     size: 0,
     icon: <Verifictaion />,
     childPageSelect: [],
   },
   // {
   //   name: "Task And Workflow",
   //   pageId: "bm890",
   //   page: Routes.taskDocument,
   //   masterLayoutId: "o4jfzd",
   //   size: 0,
   //   icon: <AssignmentTurnedInOutlinedIcon />,
   //   childPageSelect: [],
   // },
   {
     name: "Task Viewer",
     pageId: "bm89s0",
     page: Routes.taskViewer,
     repo: "Task Viewer",
     masterLayoutId: "o4jfszd",
     size: 0,
     icon: <AssignmentTurnedInIcon />,
     childPageSelect: [],
   },
   {
     name: "View Organization",
     pageId: "bm89s0",
     page: Routes.ViewOrganization,
     repo: "View Organization",
     masterLayoutId: "o4jfszd",
     size: 0,
     icon: <AssignmentTurnedInIcon />,
     childPageSelect: [],
   },
   {
     name: "View Level",
     pageId: "bm89s0",
     page: Routes.ViewLevel,
     repo: "View Level",
     masterLayoutId: "o4jfszd",
     size: 0,
     icon: <SchemaIcon />,
     childPageSelect: [],
   },
   {
     name: "Client Report",
     pageId: "bm89s0",
     page: Routes.clientReport,
     repo: "Client Report",
     masterLayoutId: "o4jfszk",
     size: 0,
     icon: <AssessmentIcon />,
     childPageSelect: [],
   },
   {
     name: "Notifier Report",
     pageId: "bm89s0",
     page: Routes.notifierReport,
     repo: "Notifier Report",
     masterLayoutId: "o4jfszs",
     size: 0,
     icon: <AssessmentIcon />,
     childPageSelect: [],
   },
   {
     name: "Verifier Report",
     pageId: "bm89s0",
     page: Routes.verifiertReport,
     repo: "Verifier Report",
     masterLayoutId: "o4jfszl",
     size: 0,
     icon: <AssessmentIcon />,
     childPageSelect: [],
   },
   {
     name: "Date Report",
     pageId: "bm89s0",
     page: Routes.dateReport,
     repo: "Date Report",
     masterLayoutId: "o4jfszp",
     size: 0,
     icon: <AssessmentIcon />,
     childPageSelect: [],
   },
 ];
 
 export const notifyTypeBesedOnEntryStatus = {
   notify: {
     1: "1",
     9: "2",
   },
   verify: {
     2: "6",
     3: "3",
     10: "7",
     11: "8",
   },
   register: {
     7: "9",
     8: "4",
     10: "10",
     11: "11",
   },
   investigate: {
     5: "12",
     6: "5",
   },
 };
 
 export const DependencyType = [
   {
     value: "form",
     label: "Form",
   },
   {
     value: "section",
     label: "Sections",
   },
   {
     value: "question",
     label: "Question",
   },
 ];
 
 export const FormComponents = {
   single_tab_select: "single_tab_select",
   multi_tab_select: "multi_tab_select",
   input_text: "input_text",
   input_textarea: "input_textarea",
   input_date_picker: "input_date_picker",
   multi_select_tag: "multi_select_tag",
   custom_multi_select_tag: "custom_multi_select_tag",
   select_modal_dropdown: "select_modal_dropdown",
   list_text: "list_text",
   input_number: "input_number",
   hours_minutes_range: "hours_minutes_range",
   grade: "grade",
   image_picker: "image_picker",
   switch_tab: "switch_tab",
   range_picker: "range_picker",
   popup_switch: "popup_switch",
   label: "label",
   note: "note",
   check_box: "check_box",
   radio_button: "radio_button",
   location: "location",
   custom_table: "custom_table",
   custom_table_2: "custom_table_2",
   mobile_no: "mobile_no",
   location_level: "location_level",
   date_of_birth: "date_of_birth",
   file_upload: "file_upload",
   Image_upload: "Image_upload",
   Image_only_upload: "Image_only_upload",
   input_time_picker: "input_time_picker",
   input_datetime_picker: "input_datetime_picker",
   select_modal_dropdown_qdm:"select_modal_dropdown_qdm"
 };
 
 export const subNavBarArr = [
   {
     id: "disease_masters",
     prioriy: 2,
     name: "Manage Disease",
     url: Routes.list_diseases,
   },
   {
     id: "manage_forms",
     prioriy: 3,
     name: "Manage Forms",
     url: Routes.manage_forms,
   },
   {
     id: "country_masters",
     prioriy: 4,
     name: "Manage Clients",
     url: Routes.list_countries,
   },
   {
     id: "manage_roles",
     prioriy: 5,
     name: "Manage Roles",
     url: Routes.roles,
   },
   {
     id: "manage_user_groups",
     prioriy: 6,
     name: "Manage User",
     url: Routes.users,
   },
   {
     id: "notify",
     prioriy: 7,
     name: "Notification",
     url: Routes.notify,
   },
   {
     id: "notification",
     prioriy: 8,
     name: "Notification Registry",
     url: Routes.notifications,
   },
   {
     id: "Caseverification",
     prioriy: 9,
     name: "Case Verification",
     url: Routes.verifications,
   },
   {
     id: "investigation",
     prioriy: 11,
     name: "Investigation",
     url: Routes.investigations,
   },
   {
     id: "caseRegistration",
     prioriy: 10,
     name: "Case Registrations",
     url: Routes.case_registration,
   },
   {
     id: "powerLens",
     prioriy: 12,
     name: "Power Lens",
     url: Routes.power_lens,
   },
   {
     id: "r3Menus",
     prioriy: 13,
     name: "R3",
     url: Routes.r3,
   },
   {
     id: "managePatients",
     prioriy: 14,
     name: "Manage Patients",
     url: Routes.manage_patients,
   },
   {
     id: "manageTransferCases",
     prioriy: 15,
     name: "Manage Transfer Cases",
     url: Routes.manage_transfer_cases,
   },
   {
     id: "dashboard",
     prioriy: 1,
     name: "Dashboard",
     url: Routes.dashboard_page,
   },
   {
     id: "home",
     prioriy: 0,
     name: "Home",
     url: Routes.home_page,
   },
   {
     id: "manageClient",
     prioriy: 16,
     name: "Manage Client",
     url: Routes.manage_Client,
   },
   {
     id: "manageFacility",
     prioriy: 17,
     name: "Manage Facility",
     url: Routes.manage_Facility,
   },
   {
     id: "manageLevel",
     prioriy: 18,
     name: "Manage Level",
     url: Routes.manage_level,
   },
   // {
   //     // add client route added
   //     id: 'add_client',
   //     prioriy: 19,
   //     name: 'Add Client',
   //     url: Routes.manage_add_client,
   // },
 ];
 
 export const genderData = [
   {
     value: "Female",
     label: "Female",
   },
   {
     value: "Male",
     label: "Male",
   }
   
 ];
 
 export const personalTitles = [
   {
     value: "Mr.",
     label: "Mr.",
   },
   {
     value: "Mrs.",
     label: "Mrs.",
   },
   {
     value: "Miss.",
     label: "Miss.",
   },
   {
     value: "Ms.",
     label: "Ms.",
   },
 ];
 
 export const Months = () => {
   const months = Array.from({ length: 12 }, (e, i) => {
     return new Date(null, i + 1, null).toLocaleDateString("en", {
       month: "short",
     });
   });
   return months;
 };
 
 export const GetLastSevenDays = () => {
   let getlast7days = Array.from({ length: 7 }, (e, i) => {
     let cur = new Date();
     let temp = new Date(cur);
 
     let val = new Date(temp.setDate(cur.getDate() - i));
     return {
       label: `${val.getDate()} ${val.toLocaleDateString("en", {
         month: "short",
       })}`,
       value: val.getDate(),
     };
   });
   return getlast7days.reverse();
 };
 export const size = {
   mobileXS: "280px",
   mobileS: "320px",
   mobileM: "375px",
   mobileL: "425px",
   tablet: "768px",
   laptop: "1024px",
   laptopL: "1440px",
   desktop: "2560px",
 };
 export const device = {
   mobileXS: `(min-width: ${size.mobileXS})`,
   mobileS: `(min-width: ${size.mobileS})`,
   mobileM: `(min-width: ${size.mobileM})`,
   mobileL: `(min-width: ${size.mobileL})`,
   tablet: `(min-width: ${size.tablet})`,
   laptop: `(min-width: ${size.laptop})`,
   laptopL: `(min-width: ${size.laptopL})`,
   desktop: `(min-width: ${size.desktop})`,
   desktopL: `(min-width: ${size.desktop})`,
 };
 export const stringToColor = (string) => {
   let hash = 0;
   let i;
 
   /* eslint-disable no-bitwise */
   for (i = 0; i < string.length; i += 1) {
     hash = string.charCodeAt(i) + ((hash << 5) - hash);
   }
 
   let color = "#";
 
   for (i = 0; i < 3; i += 1) {
     const value = (hash >> (i * 8)) & 0xff;
     color += `00${value.toString(16)}`.slice(-2);
   }
   /* eslint-enable no-bitwise */
 
   return color;
 };
 