import React from "react";
import { Grid, makeStyles, Paper, Button } from "@material-ui/core";
import { SubHeader, InternatlServerError } from "../../components";
import { NotFoundSection } from "../../components";
import { useHistory } from "react-router-dom";

import { Message } from "../../utils";
import { DrawerContext } from "../../contexts";

import { LevelDetail } from "./levelDetail";
import { DrawerProps } from "../../utils/constants";
import { AddEditLevel } from "./addEditDrawer";
import { AddPlaces } from "./addPlaces";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    height: "100%",
  },
  mainSection: {
    // marginTop: theme.spacing(1.5),
    height: "calc(100% - 40px)",
    overflow: "scroll",
    overflowX: "hidden",
    boxShadow: "unset",
  },
}));

export const ManageLocation = (props) => {
  React.useEffect(() => {
    let Levelno = Object.keys(props.data)?.length;
    let selectedlevel = {};
    for (let i = 1; i <= Levelno; i++) {
      let name = `selected_level_${i}`;
      selectedlevel[name] = {};
    }
    setState(selectedlevel);
  }, []);
  const classes = useStyles(props);
  const history = useHistory();
  const drawer = React.useContext(DrawerContext);

  const [selected, setSelected] = React.useState({});

  const [state, setState] = React.useState({
    // selected_level_1: {},
    // selected_level_2: {},
    // selected_level_3: {},
    // selected_level_4: {},
    // selected_level_5: {},
    // selected_level_6: {},
    // selected_level_7: {},
    // selected_level_8: {},
    // selected_level_9: {},
    // selected_level_10: {},
    // selected_level_11: {},
    // selected_level_12: {},
  });

  const superFunction = (levelNo, selected_child) => {
    let Levelno = Object.keys(data)?.length;
    state[`selected_level_${levelNo}`] = selected_child;

    for (let index = levelNo + 1; index <= Levelno; index++) {
      state[`selected_level_${index}`] = {};
    }
    setState(state);
  };

  const childRender = (arrayOfChilds, level) => {
    let childs = arrayOfChilds;
    let previousLevel = level - 1;

    if (level === 1) {
      return childs;
    }

    if (level > 1) {
      let filterChild = childs.filter((item) => {
        return item?.parent_child_level_id ===
          state[`selected_level_${previousLevel}`]?.child_level_id
          ? state[`selected_level_${previousLevel}`]?.child_level_id
          : "";
      });

      return filterChild;
    }
  };

  const onBackBtnClicked = () => {
    history.goBack();
  };

  const { loading, error, data } = props;

  const openAddEditLevel = (level, level_id, isEdit, level_name, alldata) => {
    drawer.setDrawer({
      ...drawer,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <AddEditLevel
          countryId={props.countryId}
          level={level}
          level_name={level_name}
          level_id={level_id}
          isEdit={isEdit}
          onActionCompleted={onActionCompleted}
          client_id={props.client_id}
          _key={alldata?._key}
          structureId={alldata?._id}
          levelrepodetails={props?.levelrepodetails}
          tenantid={props.tenantid}
          rowdetails={alldata}
        />
      ),
      onClose: closeDrawer,
    });
  };
  const openAddEditLevelPlus = (levels, level_id, isEdit, level_name) => {
    drawer.setDrawer({
      ...drawer,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <AddEditLevel
          countryId={props.countryId}
          level={levels}
          level_name={level_name}
          level_id={level_id}
          isEdit={isEdit}
          onActionCompleted={onActionCompleted}
          client_id={props.client_id}
          levelrepodetails={props?.levelrepodetails}
          tenantid={props.tenantid}
        />
      ),
      onClose: closeDrawer,
    });
  };

  const handleAddBtnClick = (
    select,
    level,
    level_name,
    childlevel,
    rowdetails
  ) => {
    // alert(childlevel);
    const previousSelectedChild = state[`selected_level_${level - 1}`];

    let levelPlus = level;
    let nextlevelName = level_name;
    let childLevelplus = childlevel + 1;
    let Masterdata = Object.entries(data);
    let filterdata = Masterdata.map((v) => {
      return v[1].level_id === previousSelectedChild?.level_id;
    });
    let ind = filterdata.indexOf(true) + 1;

    let objectEnt = Object.entries(data);
    let levelID = objectEnt[ind][1].level_id;

    drawer.setDrawer({
      ...drawer,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <AddPlaces
          // countryId={select?.country_id}
          level_id={levelID}
          level={levelPlus}
          childlevel={childLevelplus}
          level_name={previousSelectedChild?.child_name}
          nextlevelName={nextlevelName}
          parent_child_level_id={previousSelectedChild?.child_level_id}
          onActionCompleted={props?.getManageLocation}
          client_id={props.client_id}
          tenantid={props.tenantid}
          rowdetails={rowdetails}
        />
      ),
      onClose: closeDrawer,
    });
  };

  const handleAddBtnFirstClick = (
    LEVEL,
    LEVEL_ID,
    LEVEL_NAME,
    // COUNTRY_ID,
    rowdetails
  ) => {
    drawer.setDrawer({
      ...drawer,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <AddPlaces
          // countryId={COUNTRY_ID}
          level_id={LEVEL_ID}
          level={LEVEL}
          level_name={LEVEL_NAME}
          onActionCompleted={props?.getManageLocation}
          client_id={props.client_id}
          tenantid={props.tenantid}
          rowdetails={rowdetails}
        />
      ),
      onClose: closeDrawer,
    });
  };

  const checkAvailableLevels = (level, level_id, isEdit, level_name) => {
    let lengt = Object.values(data).filter((i) => {
      return i._id;
    }).length;
    let lenminus = lengt + 1;
    openAddEditLevelPlus((level = lenminus), level_id, isEdit, level_name);
  };

  const closeDrawer = () => {
    drawer.setDrawer({
      ...drawer,
      direction: DrawerProps.direction.right,
      open: false,
    });
  };

  const onActionCompleted = () => {
    closeDrawer();
    props.getManageLocation();
  };

  let levelNames = [];
  // console.log({ state });
  return (
    <div className={classes.root}>
      {/* Header */}
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
        style={{ padding: "4px 8px" }}
      >
        <Grid item>
          <SubHeader
            // title={'Manage Levels  (' + props?.manageLevelName + ')'}
            title={"Manage Levels"}
            onBackBtnClicked={onBackBtnClicked}
            noBackGround={true}
            hideBackBtn={true}
          />
        </Grid>

        <Grid item>
          {Object.values(data ?? "").filter((i) => {
            return i._id;
          }).length > 0 && (
            <Button
              id="+addLevel"
              variant="contained"
              color="primary"
              className={classes.saveBtn}
              onClick={() => {
                checkAvailableLevels();
              }}
              // disabled={upsertRole?.loading}
              disabled={props.disableaddlevel}
            >
              {"+ Add Level"}
            </Button>
          )}
        </Grid>
      </Grid>

      {/* Interaction Section */}
      {/* {loading && !error && <LoadingSection message={Message.manage_location_fetching} />} */}
      {!loading && error && (
        <InternatlServerError
          message={Message.manage_location_error}
          onRetry={props.getManageLocation}
        />
      )}
      {/* {!loading && !error && data.length === 0 && <NotFoundSection message={Message.manage_location_not_found} />} */}

      {/* Main Section */}
      <Paper className={classes.mainSection}>
        {" "}
        {(!loading && !error && data?.level1?.length === 0) ||
        (!loading && !error && data?.length === 0) ? (
          <NotFoundSection
            message={Message.manage_location_not_found}
            showActionBtn={true}
            actionBtnName="Add Level"
            onActionButtonClicked={() => openAddEditLevel(1, "", false)}
          />
        ) : (
          <Grid
            container
            direction="row"
            style={{ flexWrap: "nowrap", overflow: "auto", height: "100%" }}
          >
            {Object.values(data ?? {}).map((_d, index) => {
              if (_d.length === 0) {
                return <></>;
              } else {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    id={`${"grid" + index}`}
                    style={{
                      height: "100%",
                      minWidth: "300px",
                    }}
                  >
                    <LevelDetail
                      structure={_d._id}
                      level={index + 1}
                      level_id={_d.level_id}
                      level_name={_d.level_name}
                      parent_child_level_id={_d._id}
                      // countryId={_d.country_id}
                      onEditLevelClicked={() =>
                        openAddEditLevel(
                          index + 1,
                          _d.level_id,
                          true,
                          _d.level_name,
                          _d
                        )
                      }
                      handleAddBtnClick={() =>
                        handleAddBtnClick(
                          selected,
                          index + 1,
                          _d.level_name,
                          childRender(_d?.child, index + 1).length,
                          _d
                        )
                      }
                      handleAddBtnFirstClick={() =>
                        handleAddBtnFirstClick(
                          index + 1,
                          _d.level_id,
                          _d.level_name,
                          // _d.country_id,
                          _d
                        )
                      }
                      data={props?.data}
                      getManageLocation={props?.getManageLocation}
                      superFunction={superFunction}
                      child={childRender(_d?.child, index + 1)}
                      state={state}
                      levelNames={levelNames}
                      selected={selected}
                      setSelected={setSelected}
                      disabledadd1={props.disabledadd1}
                      disabledadd2={props.disabledadd2}
                      disabledmore={props.disabledmore}
                    />
                  </Grid>
                );
              }
            })}
          </Grid>
        )}
      </Paper>
    </div>
  );
};
