import React from "react";
import {
  Button,
  makeStyles,
  Divider,
  Typography,
  Grid,
  IconButton,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { AlertContext, BackdropContext, DialogContext } from "../../contexts";
// store
import { actions, LevelApi } from "surveillance-binder";
import { useSelector, useDispatch } from "react-redux";
import { Message } from "../../utils";
import { AlertProps } from "../../utils/constants";
import { Delete } from "@material-ui/icons";

// Store Action's list
const { UPSERT_MANAGE_LOCATION_LEVEL } = actions;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  header: {
    padding: "10px 20px",
  },
  levelNameParent: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  btnParent: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const AddEditLevel = ({
  structureId = "",
  level = "",
  level_id = "",
  level_name = "",
  isEdit = false,
  // countryId = "",
  onActionCompleted = () => false,
  client_id = "",
  _key,
  levelrepodetails,
  tenantid,
  rowdetails,
}) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    level_name: level_name ?? "",
    level_id: level_id ?? "",
    is_active: true,
    error: {
      level_name: false,
      level_id: false,
    },
  });

  // Declaring Store dispatch
  const dispatch = useDispatch();

  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const dialogContext = React.useContext(DialogContext);

  const upsertLevel = useSelector(
    (state) => state?.manageLevelSlice?.upsertManageLocationLevel
  );

  const onChangeInput = (e) => {
    setState({ ...state, level_name: e.target.value });
  };

  const validation = () => {
    if (state?.level_name?.length === 0) {
      state.error = {
        level_name: state?.level_name?.length === 0 ? true : false,
      };
      setState({ ...state });
    } else {
      return true;
    }
  };

  // const onDeleteBtnClicked = () => {
  //     submitForm(true, false);
  // }

  const submitForm = (skipValidation = false, isActive = true) => {
    const isValidated = skipValidation ? true : validation();

    if (!isValidated) {
      return false;
    }
    let __params = {
      // countryId: countryId,
      level_name: state?.level_name,
      level_id: state?.level_id,
      level: level ?? 0,
      isActive,
      client_id,
      _key,
      levelrepodetails,
      tenantid,
      rowdetails,
    };

    //  console.log(__params,"params")

    // if (__params?.level > 12) {
    //   alert.setSnack({
    //     ...alert,
    //     open: true,
    //     severity: AlertProps.severity.error,
    //     msg: "Level cannot be added . Maximum 12 level already Achieved !",
    //   });
    // } else {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: isEdit ? Message.update_level : Message.add_level,
    });

    Promise.resolve(dispatch(UPSERT_MANAGE_LOCATION_LEVEL(__params))).then(
      (res) => {
        backdrop.setBackDrop({ ...backdrop, open: false });
        if (!res.payload.error) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: isActive
              ? isEdit
                ? Message.update_level_success
                : "Level has been added successfully!"
              : level_name + " deleted successfully !",
          });
          onActionCompleted(isEdit);
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something went wrong, Try Again!",
          });
        }
      }
    );
    // }
  };

  const deleteItem = () => {
    dialogContext.setDialog({ ...dialogContext, open: false });

    submitForm(true, false);

    backdrop.setBackDrop({ ...backdrop, open: false });
  };

  const onDeleteIconClicked = async () => {
    let LevelReference = await LevelApi.levelDataDelete({
      levelData: undefined,
      levelStructure: structureId,
    });
    if (LevelReference.Code === 201) {
      dialogContext.setDialog({
        ...dialogContext,
        open: true,
        title: "Would you like to delete " + state?.level_name + " ?",
        body: "",
        positiveBtn: "Yes",
        negativeBtn: "Cancel",
        onOk: () => deleteItem(),
      });
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: LevelReference.error,
      });
    }
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        <Grid item>
          <Typography className={classes.header}>
            {isEdit ? "Update Level" : "Add Level"}
          </Typography>
          <Divider />
        </Grid>

        <Grid item className={classes.levelNameParent}>
          <TextField
            onChange={(e) => onChangeInput(e)}
            value={state?.level_name ?? ""}
            fullWidth
            label="Level name"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            error={state.error.level_name}
            helperText={
              state.error.level_name === true ? Message.requiredMessage : ""
            }
          />
        </Grid>

        <Grid
          item
          container
          justify={"space-between"}
          className={classes.btnParent}
        >
          <Grid item>
            <Button
              id="updateAddButton"
              variant="contained"
              color="primary"
              disabled={upsertLevel?.loading}
              onClick={() => submitForm()}
            >
              {isEdit
                ? upsertLevel?.loading
                  ? "Updating..."
                  : "Update Level"
                : upsertLevel?.loading
                ? "Adding..."
                : "Add Level"}
            </Button>
          </Grid>

          {isEdit && (
            <Grid item>
              <IconButton id="delete" onClick={onDeleteIconClicked}>
                <Delete color={"error"} />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
