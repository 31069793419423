import React from "react";
import { NotificationDetail } from "./notificationDetails";
import { withNavBars } from "../../HOCs";
import { withStyles } from "@material-ui/core";
import { actions } from "surveillance-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#F8F8F8",
  },
});
class NotificationDetailParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getNotifiedEntryByFormId();
  }

  getNotifiedEntryByFormId = () => {
    let formId = this?.props?.match?.params?.id;

    let params = {
      formId,
      type: "notification",
    };

    this.props.GET_ENTRIES_BY_FORM_ID(params);
  };

  render() {
    const { classes, getEntriesByFormId } = this.props;

    return (
      <div className={classes.root}>
        <NotificationDetail
          {...getEntriesByFormId}
          getNotifiedEntryByFormId={this.getNotifiedEntryByFormId}
          backButtonTitle={this.props?.location?.title ?? "Go Back"}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getEntriesByFormId: state?.workflowSlice?.getEntriesByFormId,
});

export default connect(
  mapStateToProps,
  actions
)(withNavBars(withRouter(withStyles(styles)(NotificationDetailParent))));
