import React from "react";
import {
  Box,
  InputBase,
  Select,
  TextField,
  withStyles,
} from "@material-ui/core";
import {
  Typography,
  Grid,
  makeStyles,
  Button,
  IconButton,
  MenuItem,
} from "@material-ui/core";
// import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
// import { ClientData } from "../../contexts";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";

// import { Message } from '../../utils'
const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  gridItem: {
    padding: theme.spacing(2),
    "& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button":
      {
        margin: 0,
        display: "none",
      },
  },
  hashText: {
    color: "#83859B",
  },
  textResponsive: {
    fontWeight: "600",
    fontSize: "1rem",
    flex: "1 1 auto",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem !important",
    },
  },
  heading: {
    margin: "8px 0px",
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
export const ContactDetail = (props) => {
  const classes = useStyles(props);
  const { state = null, updateState = () => false, isEdit } = props;
  //   const clientstate = React.useContext(ClientData);
  const [Addlist, SetTypeList] = React.useState(state?.contact_details ?? []);

  const onChange = (e, index) => {
    // const updatestate = Addlist?.map((input, i) =>
    //   index === i
    //     ? Object.assign(input, { [e.target.name]: e.target.value })
    //     : input
    // );
    const listupdate = [...Addlist];
    listupdate[index][e.target.name] = e.target.value;
    SetTypeList(listupdate);
    // clientstate.setValue("contact_details", Addlist);
  };

  const addNewContact = () => {
    SetTypeList([
      ...Addlist,
      {
        id: Addlist.length + 1,
        contact_mode: "",
        email: "",
        work: "",
        priority: Addlist.length + 1 ?? "",
      },
    ]);
  };

  // const changePriority = () => {
  //     console.log(props.state.contact_details?.length + 1, "props?.editData?.priority");

  //     let e = {
  //         target: {
  //             name: "priority",
  //             value: props.state.contact_details?.length + 1
  //         }
  //     }

  //     onChange(e, props.state.contact_details?.length);
  // }

  // React.useEffect(()=>{
  //     changePriority();
  // },[SetTypeList])

  const deleteIcon = (id) => {
    let Updatelist = Addlist.filter((item) => item.id !== id);
    SetTypeList(Updatelist);
  };

  React.useEffect(() => {
    updateState("contact_details", Addlist);
    // eslint-disable-next-line
  }, [Addlist]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box className={classes.heading}>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.textResponsive}
          >
            Contact Details
          </Typography>
        </Box>

        <Button
          id={"Button1T6"}
          variant="text"
          color="primary"
          onClick={addNewContact}
          // disabled={true}
        >
          + Add New Contact
        </Button>
      </div>
      {Addlist?.map((type, index) => (
        <Grid
          key={index}
          container
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={6} lg={3} className={classes.gridItem}>
            <Typography variant="subtitle2" gutterBottom color="textSecondary">
              {"Contact Mode"}
            </Typography>
            <Select
              id="selectCountry"
              classNamePrefix="select"
              // label={"Priority"}
              name="contact_mode"
              fullWidth
              variant="outlined"
              value={type?.contact_mode ?? ""}
              placeholder="Select Type"
              onChange={(e) => onChange(e, index)}
              input={<BootstrapInput />}
              size="small"
              disabled={type?.priority === 1}
            >
              <MenuItem value={"Email"}>Email</MenuItem>
              <MenuItem value={"Phone"}>Phone</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="subtitle2" gutterBottom color="textSecondary">
              {type?.contact_mode.trim().length !== 0
                ? type?.contact_mode
                : "Mode"}
            </Typography>
            <TextField
              id="selectQType"
              variant="outlined"
              size="small"
              placeholder={type?.contact_mode}
              fullWidth
              margin="none"
              onChange={(e) => onChange(e, index)}
              name="email"
              value={type?.email ?? ""}
              disabled={isEdit}
            />
          </Grid>

          <Grid item xs={12} sm={4} lg={2} className={classes.gridItem}>
            <Typography variant="subtitle2" gutterBottom color="textSecondary">
              {"Work"}
            </Typography>
            <Select
              id="selectCountry"
              classNamePrefix="select"
              name="work"
              fullWidth
              variant="outlined"
              value={type?.work ?? ""}
              placeholder="Select Type"
              onChange={(e) => onChange(e, index)}
              size="small"
              disabled={type?.priority === 1}
              input={<BootstrapInput />}
            >
              <MenuItem value={"Personal"}>Personal</MenuItem>
              <MenuItem value={"Primary"}>Primary</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} lg={2} className={classes.gridItem}>
            <Typography variant="subtitle2" gutterBottom color="textSecondary">
              {"Priority"}
            </Typography>
            {index === 0 && (
              <Select
                id="selectCountry"
                classNamePrefix="select"
                name="priority"
                fullWidth
                variant="outlined"
                value={type?.priority ?? ""}
                placeholder="Select Type"
                onChange={(e) => onChange(e, index)}
                size="small"
                input={<BootstrapInput />}
                disabled={type?.priority === 1}
              >
                {/* <MenuItem value={1}>01</MenuItem> */}
                {/* <MenuItem value={2}>02</MenuItem> */}
                <MenuItem value={index + 1}>{index + 1}</MenuItem>
              </Select>
            )}
            {index !== 0 && (
              <Select
                id="selectCountry"
                classNamePrefix="select"
                name="priority"
                fullWidth
                variant="outlined"
                value={index + 1}
                placeholder="Select Type"
                onChange={(e) => onChange(e, index)}
                size="small"
                input={<BootstrapInput />}
                disabled={true}
              >
                {/* <MenuItem value={1}>01</MenuItem> */}
                {/* <MenuItem value={2}>02</MenuItem> */}
                <MenuItem value={index + 1}>{index + 1}</MenuItem>
              </Select>
            )}
          </Grid>

          {type?.priority !== 1 && (
            <Grid item xs={12} sm={1} lg={1}>
              <IconButton
                id={"deleteBtn"}
                style={{ marginTop: "22px" }}
                onClick={() => deleteIcon(type.id)}
                disabled={type?.priority === 1}
              >
                <DeleteOutlineOutlinedIcon color="error" />
              </IconButton>
            </Grid>
          )}
        </Grid>
      ))}
    </>
  );
};
