import { Box, makeStyles, Slide } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actions } from "surveillance-binder";
import { AlertContext } from "../../contexts";
import { withNavBars } from "../../HOCs";
import { Routes } from "../../router/routes";
import { AlertProps } from "../../utils";
import { AddEditClient } from "./addEditClientComponent";

import ListOfClients from "./listOfClients";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  container: {
    zIndex: 1,
    // position: "relative",
    height: "100%",
  },
}));
const Client = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const alertContext = React.useContext(AlertContext);
  const clientList = useSelector((state) => {
    return state?.manageClientSlice?.getAllClient;
  });
  const [addEdit, setAddEdit] = React.useState({
    isEdit: false,
    editData: null,
    toggle: false,
  });
  //   const [mswitch, setSwicth] = React.useState(true);
  // console.log("actions", actions);
  React.useEffect(() => {
    getAllClient();
  }, []);

  const getAllClient = async () => {
    try {
      await dispatch(actions.GET_All_CLIENT());
    } catch (error) {
      console.log(error);
    }
  };
  const onAddNewClicked = () => {
    history.push({
      pathname: Routes.CreateClient,
      state: { isEdit: false, _id: null },
    });
  };

  const onEditClicked = (data) => {
    history.push({
      pathname: Routes.CreateClient,
      state: { isEdit: true, _id: data?.currentmapobject?._id },
    });
  };

  const onDeleteClicked = async (data) => {
    let res = await dispatch(
      actions.SOFT_DELETE_CLIENT({
        _key: data?.currentmapobject?._key,
        tenentid: data?.currentmapobject?.tenantid,
      })
    );
    // console.log(res);
    if (!res.payload.error) {
      alertContext.setSnack({
        ...alertContext,
        open: true,
        severity: AlertProps.severity.success,
        msg: "Client deleted successfully",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
      await getAllClient();
    } else {
      alertContext.setSnack({
        ...alertContext,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Unable to delete client !",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };

  return (
    // <Box className={classes.root}>
    //   <Box className={classes.paper}>
    <ListOfClients
      onAddNewClicked={onAddNewClicked}
      onEditClicked={onEditClicked}
      clientList={clientList?.data}
      onDeleteClicked={onDeleteClicked}
    />
    //   </Box>
    // </Box>
  );
};

export default withNavBars(Client);
