import {
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    background: theme.palette.background.paper,
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  textWrapper: {
    display: "flex",
    alignItems: "center",
  },
  textResponsive: {
    // fontWeight: "600",
    fontSize: "1rem",
    flex: "1 1 auto",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem !important",
    },
  },
}));

export const Card1 = (props) => {
  const classes = useStyles();
  const { keyValues = {}, onEditClicked = () => false } = props;
  return (
    <Grid item xs={12} lg={12} md={12} sm={12}>
      <Box className={classes.cardWrapper}>
        <Box style={{ flex: "1 1 auto" }}>
          <Box className={classes.textWrapper}>
            {Object.values(keyValues)?.map((l, index) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                {index !== 0 && (
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{ margin: "0px 8px" }}
                  />
                )}
                <Typography
                  variant={index === 0 ? "h6" : "body2"}
                  className={
                    index === 0
                      ? classes.textResponsive
                      : classes.captionResponsive
                  }
                >
                  {l}
                </Typography>
              </div>
            ))}
          </Box>
        </Box>
        <Button
          variant="outlined"
          onClick={() => onEditClicked()}
          startIcon={<EditIcon />}
        >
          Edit
        </Button>
      </Box>
    </Grid>
  );
};
