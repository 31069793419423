import React from "react";
import * as WebDataRocksReact from "react-webdatarocks";
import "webdatarocks/webdatarocks.css";
import { withNavBars } from "../../HOCs";
import DateFnsUtils from "@date-io/date-fns";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import RequiredField from "../../components/common/requiredField";
import TodayIcon from "@material-ui/icons/Today";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { AlertProps } from "../../utils";
import { AlertContext } from "../../contexts";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Grid,
  Paper,
  Typography,
  Chip,
  makeStyles,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));
const ReportDate = () => {
  const pivot = React.useRef(null);
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [showTo, setShowTo] = React.useState(new Date());
  const [showFilter, setShowFilter] = React.useState(false);

  const [showFrom, setShowFrom] = React.useState(new Date());
  const alert = React.useContext(AlertContext);
  const getDates = (dateval) => {
    var date = new Date(dateval).getDate();
    var months = new Date(dateval).getMonth() + 1;
    var year = new Date(dateval).getFullYear();
    months = months.toString().length !== 2 ? `0${months}` : months;
    date = date.toString().length !== 2 ? `0${date}` : date;
    var result = `${year}-${months}-${date}`;
    return result;
  };
  const handleClose = () => {
    setOpen(false);
  };
  React.useLayoutEffect(() => {}, []);
  function customizeToolbar(toolbar) {
    let tabs = toolbar.getTabs();
    toolbar.getTabs = function () {
      delete tabs[0];
      delete tabs[1];
      delete tabs[2];
      delete tabs[4];
      delete tabs[5];
      return tabs;
    };
  }
  let singleData = [];
  const singleLevel = (data) => {
    setShowFilter(true);
    for (let i = 0; i < data.length; i++) {
      let Result = {
        "Client Name":
          data[i].formdetails[0]?.length !== 0
            ? data[i].formdetails[0].clientname
            : "",
        "Form Entry ID":
          data[i].formdetails[0]?.length !== 0
            ? data[i].formdetails[0].formentryid
            : "",
        "Patient Name":
          data[i].patientDetails[0]?.length !== 0
            ? ` ${
                data[i]?.patientDetails[0]?.name?.hasOwnProperty("first_name")
                  ? data[i]?.patientDetails[0]?.name?.first_name
                  : "-"
              } ${
                data[i]?.patientDetails[0]?.name?.hasOwnProperty("middle_name")
                  ? data[i]?.patientDetails[0]?.name?.middle_name
                  : ""
              } ${
                data[i]?.patientDetails[0]?.name?.hasOwnProperty("last_name")
                  ? data[i]?.patientDetails[0]?.name?.last_name
                  : ""
              }`
            : "",
        gender:
          data[i].patientDetails[0]?.length !== 0 &&
          data[i].patientDetails[0]?.hasOwnProperty("gender")
            ? data[i].patientDetails[0]?.gender
            : "-",
        age:
          data[i].patientDetails[0]?.length !== 0 &&
          data[i].patientDetails[0]?.hasOwnProperty("age")
            ? data[i].patientDetails[0]?.age
            : "-",
        "mobile No":
          data[i].patientDetails[0]?.length !== 0 &&
          data[i]?.patientDetails[0]?.mobileno?.hasOwnProperty("number") &&
          data[i]?.patientDetails[0]?.mobileno?.hasOwnProperty("phoneCode")
            ? ` ${data[i]?.patientDetails[0]?.mobileno?.phoneCode}${data[i]?.patientDetails[0]?.mobileno?.number}`
            : "-",
        // email:
        //   data[i].patientDetails[0]?.length !== 0 &&
        //   data[i].patientDetails[0]?.hasOwnProperty("email")
        //     ? data[i].patientDetails[0]?.email
        //     : "-",
        "Notifier Status":
          data[i].formdetails[0]?.length !== 0
            ? data[i].formdetails[0].status.Notifier_Status
            : "",
        "Verifier Status": data[i].formdetails[0].status.Verifier_Status,
      };
      singleData.push(Result);
    }

    return singleData;
  };
  const normalize = (datas) => {
    let data = [];
    datas.forEach((e, i) => {
      if (datas[i].formdetails.length <= 1) {
        data.push(datas[i]);
      } else {
        datas[i].formdetails.forEach((e, j) => {
          let patientDetail = [...datas[i].patientDetails];
          data.push({
            formdetails: [datas[i].formdetails[j]],
            patientDetails: patientDetail,
          });
        });
      }
    });
    return data;
  };
  const validation = () => {
    let valid = false;
    if (fromDate !== null && toDate !== null) return (valid = true);
    return valid;
  };
  const handleSubmit = async () => {
    if (validation()) {
      const myHeader = new Headers();
      myHeader.append("content-type", "application/json");
      const data = await fetch(
        process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES,
        {
          method: "post",
          headers: myHeader,
          body: JSON.stringify({
            db_name: process.env.REACT_APP_DBNAME,
            filter: {
              fromdate: Date.parse(fromDate) / 1000,
              enddate: Date.parse(toDate) / 1000,
            },
            queryid: process.env.REACT_APP_REPORT_DATEFILTER_QUERY,
          }),
        }
      );
      const result = await data.json();
      var report = {
        dataSource: {
          data: singleLevel(normalize(result.collection?result.collection:result)),
        },
        options: {
          grid: {
            type: "flat",
            showGrandTotals: "off",
            showTotals: "off",
          },
        },
      };
      if (report.dataSource.data.length === 0) {
        pivot.current.webdatarocks.clear();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.warning,
          msg: "No Record",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else if (pivot.current?.webdatarocks !== null) {
        pivot.current.webdatarocks.setReport(report);
      }
      handleClose();
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Required Fields Are Empty",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };
  return (
    <React.Fragment>
      <div style={{ padding: "5px" }}>
        <Paper style={{ padding: "8px" }} elevation={0}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="body1">FILTERED BY</Typography>{" "}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                size="small"
                variant="outlined"
                onClick={() => setOpen(true)}
                startIcon={<FilterListRoundedIcon />}
                style={{ margin: "10px 0" }}
              >
                Filter
              </Button>
            </div>
          </div>
          {showFilter && (
            <>
              <Chip
                className={classes.chip}
                icon={<TodayIcon />}
                label={`From Date : ${getDates(showFrom)
                  .split("-")
                  .reverse()
                  .join("-")}`}
              />
              <Chip
                className={classes.chip}
                icon={<TodayIcon />}
                label={`To Date : ${getDates(showTo)
                  .split("-")
                  .reverse()
                  .join("-")}`}
              />
            </>
          )}
        </Paper>
        <Divider />
        <WebDataRocksReact.Pivot
          ref={pivot}
          toolbar={true}
          width="100%"
          beforetoolbarcreated={customizeToolbar}
        />
      </div>
      <Dialog
        fullWidth
        maxWidth={"sm"}
        open={open}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Filter</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={1}>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                justifyContent="space-between"
                spacing={1}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Typography style={{ color: "black" }}>
                      From Date
                      <RequiredField color={"red"} />
                    </Typography>

                    <KeyboardDatePicker
                      fullWidth
                      size="small"
                      value={fromDate}
                      placeholder="dd-mm-yyyy"
                      onChange={(date) => {
                        setFromDate(date);
                        setShowFrom(date);
                      }}
                      format="dd/MM/yyyy"
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Typography style={{ color: "black" }}>
                      To Date
                      <RequiredField color={"red"} />
                    </Typography>

                    <KeyboardDatePicker
                      fullWidth
                      size="small"
                      value={toDate}
                      placeholder="dd-mm-yyyy"
                      onChange={(date) => {
                        setToDate(date);
                        setShowTo(date);
                      }}
                      format="dd/MM/yyyy"
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={handleSubmit}>
            submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default withNavBars(ReportDate);
