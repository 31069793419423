import React from "react";
import { withRouter } from "react-router-dom";
import { ReportViewer } from "report_viewer_atp";
import { withNavBars } from "../../HOCs";
import "flexmonster/flexmonster.css";

const Reportviewer = () => {
  return (
    <div>
      <ReportViewer projectId="projects/187315907" />
    </div>
  );
};

export default withRouter(withNavBars(Reportviewer));
