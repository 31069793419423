/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc Collection of all the message used in the application.
 */

//Normal messagess
export let Message = {
  //Diseases List Message
  diseases_fetching: "Fetching the diseases...",
  diseases_not_found:
    "Well! You don't have any disease to show, Please Add One.",
  diseases_error: "Opps! Something went wrong, Try Again",

  disease_add: "Adding New Disease...",
  disease_update: "Updating Disease Details...",
  disease_delete: "Deleting Disease...",

  disease_delete_title: "Are you sure you want to delete Disease?",
  disease_delete_message:
    "If you delete this Disease all the disease and forms tagged to this Disease will be UnMapped.",
  disease_delete_btn: "I'm aware delete",

  //Add Level
  add_level: "Addding New Level...",
  add_level_success: "New Level Added Successfully!!!",
  update_level: "Updating Level Details...",
  update_level_success: "Successfully Updated Level Details!!!",
  delete_level: "Deleting Level...",
  level_delete_title: "Are you sure you want to delete the Level?",

  //List Country Message
  countries_fetching: "Fetching the countries...",
  countries_not_found:
    "Well! You don't have any country to show, Please Add One",
  countries_error: "Opps! Something went wrong, Try Again",

  country_add: "Adding New Country...",
  country_update: "Updating Country Details...",
  country_delete: "Deleting Country...",

  country_delete_title: "Are you sure you want to delete country?",
  country_delete_message:
    "If you delete this country all the disease and forms tagged to this country will be UnMapped.",
  country_delete_btn: "I'm aware delete",

  country_disease_fetching: "Fetching mapped diseases...",
  country_disease_not_found:
    "Well! You are yet to Map a disease to this country. Go back and map a disease.",
  country_disease_form_not_found:
    "Well! You are yet to Map a form to this disease.",
  //Manage Forms Messages
  manage_form_fetching: "Fetching the forms...",
  manage_form_not_found:
    "Well! You don't have any forms to show, Please Add one.",
  manage_form_error: "Opps! Something went wrong, Try Again",

  manage_form_add: "Addding New Form...",
  manage_form_update: "Updating Form Details...",
  manage_form_delete: "Deleting Form...",

  manage_form_delete_title: "Are you sure you want to delete the form?",
  manage_form_delete_message:
    "If you delete this form all the Sections and Forms tagged to this form will be deleted.",
  manage_form_delete_btn: "I'm aware delete",
// Add Facility
requiredFacilityType : 'Please Select Facility Type !',
requiredLevelOfCare : 'Please Select level Of Care !',
requiredFacilityName : 'Please Fill Facility Name !',
requiredFacilityCode : 'Please Fill Facility Code !',
requiredParentEntity : 'Please Fill Parent Entity !',
requiredAddress:"Please Fill Address !",
requiredContactDetails:"Please Fill Contact Details !",
// requiredFacilityAdminEmail : 'Please Fill Facility Admin Email !',

//manageFacility
manage_Facility_select: "select a Oragnization",
manage_Facility_not_found: "Opps! 404 - not found",
manage_Facility_data_fetch: "Loading....",

  //Section Message
  section_add: (sec) => `Addding New ${sec}...`,
  section_update: (sec) => `Updating ${sec} details...`,
  section_delete: (sec) => `Deleting ${sec}...`,

  section_delete_title: "Are you sure?",
  section_delete_message:
    "If you delete this section/form all the forms/questions mapped to this section/form will be removed.",
  section_delete_btn: "I'm aware delete",
  data_security_delete_message: (mappingDetail) => (
    <>
      Do you want to delete this mapping? <br />
      <br />
      {mappingDetail}
    </>
  ),
};
