import React from "react";
import {
  makeStyles,
  Divider,
  Typography,
  Grid,
  Button,
} from "@material-ui/core";
import JoditEditor from "jodit-react";
import { actions } from "surveillance-binder";
import { useSelector, useDispatch } from "react-redux";
import { AlertContext } from "../../contexts";
import { AlertProps, Message } from "../../utils";

// Store Action's list
const { UPSERT_DISEASE } = actions;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  header: {
    padding: "10px 20px",
    cursor: "default",
  },
}));

export const InfoPanel = ({
  data = {},
  isReadOnly = true,
  hideHeader = false,
  onActionCompleted,
  getDiseasesList = () => false,
}) => {
  const classes = useStyles();

  // Declaring Store dispatch
  const dispatch = useDispatch();

  const alertContext = React.useContext(AlertContext);

  const upsertDisease = useSelector(
    (state) => state?.diseaseSlice?.upsertDisease
  );

  const config = {
    readonly: isReadOnly,
    toolbar: !isReadOnly,
    height: "92vh",
    width: "100%",
    uploader: {
      insertImageAsBase64URI: true,
    },
    showCharsCounter: !isReadOnly,
    showWordsCounter: !isReadOnly,
    showXPathInStatusbar: !isReadOnly,
    iframe: true,
  };

  const onSaveBtnClicked = () => {
    if (isReadOnly) {
      return false;
    }

    let __params = {
      diseaseId: data?.disease_id,
      diseaseDefinition: document.getElementById("dd_editor").value ?? "",
      isActive: true,
    };

    Promise.resolve(dispatch(UPSERT_DISEASE(__params))).then((res) => {
      onActionCompleted();
      if (res.payload.error) {
        alertContext.setSnack({
          ...alertContext,
          open: true,
          severity: AlertProps.severity.error,
          msg: Message.dd_error_message,
          horizontal: AlertProps.horizontal.right,
          vertical: AlertProps.vertical.top,
        });
      } else {
        alertContext.setSnack({
          ...alertContext,
          open: true,
          severity: AlertProps.severity.success,
          msg: Message.dd_success_message,
          horizontal: AlertProps.horizontal.right,
          vertical: AlertProps.vertical.top,
        });

        getDiseasesList();
      }
    });
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        {!hideHeader && (
          <Grid
            item
            container
            justify="space-between"
            display="flex"
            alignItems="center"
          >
            <Typography className={classes.header}>
              {"Case Definition"}
            </Typography>

            {!isReadOnly && (
              <Button
                id="saveButton"
                disabled={upsertDisease?.loading}
                onClick={onSaveBtnClicked}
                color="primary"
              >
                {upsertDisease.loading ? "Saving..." : "Save"}
              </Button>
            )}
          </Grid>
        )}
        {!hideHeader && <Divider />}

        <Grid item>
          <JoditEditor
            id={"dd_editor"}
            value={data?.disease_definition}
            config={config}
            // onBlur={onSaveBtnClicked}
          />
        </Grid>
      </Grid>
    </div>
  );
};
