import { Box, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { SubHeader } from "../../../components";
import VerticalLinearStepper from "./stepperForm";
const useStyles = makeStyles((theme) => ({
  content: {
    width: "100%",
    height: "calc(100% - 104px)",
    overflowY: "auto",
  },
  container: {
    zIndex: 1,
    position: "relative",
  },
}));
export const AddEditClient = (props) => {
  const classes = useStyles();

  const { isEdit = false, editData = {}, onBackBtnClicked } = props;
  const intialState = {
    client_id: editData?.client_id ?? "",
    client_name: editData?.country_name ?? "",
    client_type: editData?.client_type ?? "",
    contact_details: editData?.contact_details ?? [
      {
        id: 1,
        contact_mode: "Email",
        email: "",
        work: "Primary",
        priority: 1,
      },
    ],
    address: editData?.address ?? [],
    editData_security: editData?.data_security ?? "",
    previlages: editData?.previlages ?? "",
    is_active: editData?.is_active ?? true,
    is_role_active: editData?.is_role_active ?? true,
    role_id: editData?.role_id ?? "",
    is_user_role_active: editData?.is_role_active ?? true,
    user_role_id: editData?.user_role_id ?? null,
  };
  const [state, setState] = React.useState(intialState);

  const updateState = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  //   const submitDetails=()=>{

  //   }
  return (
    <>
      <Grid container>
        <SubHeader
          //   searchField={false}
          title={isEdit ? "Update Client" : "Create New Client"}
          hideBackBtn={false}
          onBackBtnClicked={onBackBtnClicked}
          hideSearch={true}
          hideHeaderBtn={true}
        />
      </Grid>
      <Box className={classes.content}>
        <VerticalLinearStepper
          isEdit={isEdit}
          state={state}
          updateState={updateState}
        />
      </Box>
    </>
  );
};
