import { makeStyles, Grid, Typography } from "@material-ui/core";
import React from "react";
import { Checkbox } from "@material-ui/core";
import { Routes } from "./../../router/routes";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { useDispatch, useSelector } from "react-redux";
  import { idmapis, diseaseapis, LevelApi } from "surveillance-binder";
const useStyles = makeStyles((theme) => ({
  root: {
    float: "right",
    padding: 6,
    marginBottom: 0,
  },
  patientDetailSection: {
    maxHeight: 100,
    overflow: "auto",
  },
}));

export const UserDetailCard = (props) => {
  const {
    patient_id = {},
    patient_name = {},
    gender = {},
    mobile = {},
    email = "",
    dob_age = {},
    country = {},
    onselect = () => false,
    showSelectOptions = false,
    id = "",
    parent_form_id,
  } = props;

  const classes = useStyles();
  const userData = useSelector((state) => state?.authSlice?.login?.data);
  const [showDetails, setShowDetails] = React.useState([]);
  const [custData, setlevelData] = React.useState([]);
  const [pci, setPci] = React.useState([]);
  React.useEffect(() => {
    (async () => {
      let Client_ID = await idmapis.getclientif_from_tenantid(
        localStorage.getItem("tenentid")
      );
      let MappedDiseaseTable = await diseaseapis.getDiseaseData({
        client_id: Client_ID?.result[0]._id,
        form_id: `forms/${parent_form_id}`,
      });
      let OrgName = await LevelApi.getOrgName(
        MappedDiseaseTable.result[0].mappingTable.selectedOrg._id
      );
      let custName = await LevelApi.getOrgName(
        OrgName.result[0].ParentOrgID[0]
      );
      setPci(OrgName.result[0].PCILocation);
      setShowDetails(OrgName.result[0].name);
      setlevelData(custName.result[0].name);
    })();
  }, []);
  const giveMeFieldToShow = (v, l) => {
    if (Array.isArray(v[l?.field])) {
      //If the value is array then retrun the string.
      return v[l?.field]
        ?.map((_) => {
          //If the array child is the label,value pare then return only label
          if (_?.label) {
            return _?.label;
          }
          return _;
        })
        .toString();
    } else if (v[l?.field]?.label) {
      //If the value is object with value,label pair then return label only
      return v[l?.field]?.label;
    } else if (v[l?.field]?.address) {
      //If the value is a location object then return the formated address
      return `${v[l?.field].address} (${v[l?.field]?.latitude}, ${
        v[l?.field]?.longitude
      })`;
    } else if (v[l?.field]?.tableData) {
      //If the value is the table type,
      return "-";
    } else if (v[l?.field]?.number) {
      return `${v[l?.field]?.phoneCode} ${v[l?.field]?.number}`;
    } else if (v[l?.field]?.country) {
      return `${v[l?.field]?.country?.value}`;
    } else {
      //If nothing mathes the above conditions
      return v[l?.field];
    }
  };

  return (
    <Grid container spacing={2} style={{ padding: "0  20px" }}>
      <Grid item sm={6} md={4} lg={4} xl={4} xs={12}>
        <Typography variant="body1">Facility</Typography>
        <Typography variant="body2">
          {showDetails ? showDetails : "-"}
        </Typography>
      </Grid>
      <Grid item sm={6} md={4} lg={4} xl={4} xs={12}>
        <Typography variant="body1">Cluster</Typography>
        <Typography variant="body2">{custData ? custData : "-"}</Typography>
      </Grid>
      <Grid
        item
        sm={6}
        md={4}
        lg={4}
        xl={4}
        xs={12}
        style={{ display: "grid", placeContent: "center" }}
      >
        <Typography variant="body1">
          {pci ? "PCI Location" : "Non PCI Location"}
        </Typography>
      </Grid>
    </Grid>
  );
};
