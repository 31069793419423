import React from "react";
import {
  Typography,
  Grid,
  makeStyles,
  Button,
  IconButton,
} from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditIcon from "@material-ui/icons/Edit";
import { DrawerContext } from "../../contexts";
import { DrawerProps } from "../../utils";
import { AddAddressInput } from "./addAddressInput";
const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  gridItem: {
    padding: theme.spacing(2),
    "& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button":
      {
        margin: 0,
        display: "none",
      },
  },
  Addresscard: {
    width: "100%",
    padding: "10px 0",
    backgroundColor: "#F9F9F9",
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    border: " 1px solid #E0E0E0",
    marginBottom: "10px",
  },
  numbertag: {
    backgroundColor: "#E0E0E0",
    padding: "5px 7px",
    borderRadius: "0 5px 5px 0",
  },
  spacebtw: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 10px",
  },
}));

export const AddAddress = ({ Address = [], updateAddress = () => false }) => {
  const classes = useStyles();

  const drawerContext = React.useContext(DrawerContext);

  const onAddAddressClicked = (index, isEdit = false) => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <AddAddressInput
          updateAddress={updateAddress}
          Address={Address}
          index={index}
          isEdit={isEdit}
          onClose={onDrawerClosed}
        />
      ),
      onClose: () => onDrawerClosed(),
    });
  };
  const onDrawerClosed = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      direction: DrawerProps.direction.right,
      open: false,
    });
  };
  const onDeleteClicked = (index) => {
    let Updatedlist = Address?.filter((item, i) => i !== index);
    updateAddress("Address", Updatedlist);
  };

  return (
    <div>
      {/* <Paper style={{ width: "100%", padding: "10px" }} elevation="2"> */}
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex" }}>
          <Typography variant="body1">ADDRESS</Typography>
          <Typography
            variant="caption"
            style={{ color: "red", marginLeft: "2px" }}
          >
            *
          </Typography>
        </div>
        <Button
          id={"AddNewAddressBtn"}
          variant="text"
          color="primary"
          onClick={onAddAddressClicked}
        >
          +Add New Address
        </Button>
      </div>

      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item xs={12} sm={12} lg={12} className={classes.gridItem}>
          {Address.map((data, index) => {
            return (
              <div key={index} className={classes.Addresscard}>
                <div className={classes.numbertag}>
                  <Typography variant="body1">{index + 1}</Typography>
                </div>
                <div className={classes.spacebtw}>
                  <div>
                    <Typography variant="body1">{data?.use?.label}</Typography>
                    <Typography variant="caption">
                      {data?.address1 +
                        ", " +
                        data?.address2 +
                        ", " +
                        data?.address3}
                    </Typography>
                    <Typography variant="caption">
                      {", " +
                        data?.city +
                        ", " +
                        data?.district +
                        ", " +
                        data?.state +
                        ", " +
                        data?.country +
                        ", " +
                        data?.pincode}
                    </Typography>
                  </div>
                  <div>
                    <IconButton
                      id={"editBtn"}
                      onClick={() => onAddAddressClicked(index, true)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      id={"deleteBtn"}
                      onClick={() => onDeleteClicked(index)}
                    >
                      <DeleteOutlineOutlinedIcon color="error" />
                    </IconButton>
                  </div>
                </div>
              </div>
            );
          })}
        </Grid>
      </Grid>

      {/* </Paper> */}
    </div>
  );
};
