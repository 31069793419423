import { ValidateEmail , ValidateTime , ValidateDateTime } from "../../utils";

export const checkStringValidations = (validations,val,id,callBack,label='') =>{
    if (val && validations && Object.keys(validations)?.length > 0){
      if(validations?.regexValidation && Object.keys(validations?.regexValidation)?.length > 0 && validations?.regexValidation?.value === 'emailRegex'){
        if(ValidateEmail(val)){
          return ''
        }
        else{ 
            callBack(id,'Enter a valid email address')
        }
      }
      else if(validations?.regexValidation && Object.keys(validations?.regexValidation)?.length > 0 && validations?.regexValidation?.value === 'timeRegex')
      {
        debugger
        if(ValidateTime(val))
        {
          return ''
        }
        else{
          callBack(id,'Enter a valid Time')
        }
      }

      else{
        let minVal = validations?.minLength ? parseInt(validations?.minLength) : null;
        let maxVal = validations?.maxLength ? parseInt(validations?.maxLength) : null;

        if(minVal === null || maxVal === null){
          if(minVal === null){
            if(val?.length < maxVal){
              return ''
            }
            else{
                callBack(id,`${label && label+"'s"} Length should be lesser than ${validations?.maxLength}`)
            }
          }
          else if(maxVal === null){
            if(val?.length > minVal){
              return ''
            }
            else{
                callBack(id,`${label && label+"'s"} Length should be greater  than ${validations?.minLength}`)
            }
          }
        }
        else{
          if(val?.length < maxVal && val?.length > minVal){
            return ''
          }
          else{
            callBack(id,`${label && label+"'s"} Length should be greater than ${validations?.minLength} and lesser than ${validations?.maxLength}`)
          }
        }
      }
    }
    else{
      if(val && val.trim().length > 0){
        return ''
      }
      else{
        callBack(id,`Please fill the required field`)
      }
    }
  }
  
  export const checkNumericValidations = (validations,val,id,callBack,label='') =>{
    if (val && validations && Object.keys(validations)?.length > 0){
    
        let minVal = validations?.minLength ? parseInt(validations?.minLength) : null;
        let maxVal = validations?.maxLength ? parseInt(validations?.maxLength) : null;

        if(minVal === null || maxVal === null){
          if(minVal === null){
            if(val?.length < maxVal){
              return ''
            }
            else{
                callBack(id,`${label && label+"'s"} Length should be lesser than ${validations?.maxLength}`)
            }
          }
          else if(maxVal === null){
            if(val?.length > minVal){
              return ''
            }
            else{
                callBack(id,`${label && label+"'s"} Length should be greater  than ${validations?.minLength}`)
            }
          }
        }
        else{
          if(val?.length < maxVal && val?.length > minVal){
            return ''
          }
          else{
            callBack(id,`${label && label+"'s"} Length should be greater than ${validations?.minLength} and lesser than ${validations?.maxLength}`)
          }
        }
    }
    else{
      if(val && val.trim().length > 0){
        return ''
      }
      else{
        callBack(id,`Please fill the required field`)
      }
    }
  }

  export const checkDobValidations = (validations,val,id,callBack) =>{
    
    
    if (val>=0 && validations && Object.keys(validations)?.length > 0){

        let minVal = validations?.minAge ? parseInt(validations?.minAge) : null;
        let maxVal = validations?.maxAge ? parseInt(validations?.maxAge) : null;

        if(minVal === null || maxVal === null){
          if(minVal === null){
            if(val < maxVal){
              return ''
            }
            else{
                callBack(id,`Age should be lesser than ${validations?.maxAge}`)
            }
          }
          else if(maxVal === null){
            if(val > minVal){
              return ''
            }
            else{
                callBack(id,`Age should be greater than ${validations?.minAge}`)
            }
          }
        }
        else{
          if(val < maxVal && val > minVal){
            return ''
          }
          else{
            callBack(id,`Age should be bewteen  ${validations?.minAge} and  ${validations?.maxAge}`)
          }
        }
    }
    else{
      if(val){
        return ''
      }
      else{
        callBack(id,`Please fill the required field`)
      }
    }
  }

  export const checktimevalidation = (validations,val,id,callBack,label='') => {

    if(ValidateTime(val))
        {
          return ''
        }
        else{
          callBack(id,'Enter a valid Time')
        }
  }
  export const checkdatetimevalidation = (validations,val,id,callBack,label='') => {

    if(ValidateDateTime(val))
        {
          return ''
        }
        else{
          callBack(id,'Enter a valid Date Time')
        }
  }