import {
  Button,
  Chip,
  Divider,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import TodayIcon from "@material-ui/icons/Today";
import React from "react";
const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const getDates = (dateval) => {
  var date = new Date(dateval).getDate();
  var months = new Date(dateval).getMonth() + 1;
  var year = new Date(dateval).getFullYear();
  months = months.toString().length !== 2 ? `0${months}` : months;
  date = date.toString().length !== 2 ? `0${date}` : date;
  var result = `${year}-${months}-${date}`;
  return result;
};

function ReportTemplate(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div style={{ padding: "5px" }}>
        <Paper style={{ padding: "8px" }} elevation={0}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="body1">FILTERED BY</Typography>{" "}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                size="small"
                variant="outlined"
                onClick={() => props?.setOpen(true)}
                startIcon={<FilterListRoundedIcon />}
                style={{ margin: "10px 0" }}
              >
                Filter
              </Button>
            </div>
          </div>
          {props?.showFilter && (
            <>
              <Chip
                className={classes.chip}
                icon={<TodayIcon />}
                label={`From Date : ${getDates(props?.showFrom)
                  .split("-")
                  .reverse()
                  .join("-")}`}
              />
              <Chip
                className={classes.chip}
                icon={<TodayIcon />}
                label={`To Date : ${getDates(props?.showTo)
                  .split("-")
                  .reverse()
                  .join("-")}`}
              />
            </>
          )}
        </Paper>
        <Divider />
      </div>
    </React.Fragment>
  );
}

export default ReportTemplate;
