import { Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { actions } from "surveillance-binder";
import { AlertContext, DialogContext } from "../../../../contexts";
import { AlertProps, Message } from "../../../../utils";
import MappingTable from "./mappingTable";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    backgroundColor: "#F8F8F8",
  },
  mapform: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
  },
  main: {
    backgroundColor: "#FFFFFF",
    marginTop: "20px",
  },
  main2: {
    backgroundColor: "#FFFFFF",
  },
  textcolor: {
    // color: theme.palette.hash.primary,
    marginBottom: "6px",
  },
}));

export const ClientMapping = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const alert = React.useContext(AlertContext);
  const dialogContext = React.useContext(DialogContext);
  const [mappingstate, setState] = React.useState({
    disease: null,
    form: null,
    error: {
      country: "",
      selectedLevel: "",
      mappingTable: "",
    },
  });
  const [row, setRow] = React.useState(
    props?.state?.data_security ?? { mappingTable: [] }
  );

  //   const mappedDiseaseData = useSelector(
  //     (state) => state?.diseaseSlice?.getAllDiseases
  //   );
  const mappedDiseaseData = diseaseList;
  //   const mappedFormData = useSelector((state) => state?.formSlice?.getAllForms);

  const changeState = (key, value, startLevel = 0) => {
    let updatedState = mappingstate;
    updatedState[key] = value;
    updatedState.error[key] = "";

    onStateChangeCallback(key, value, updatedState, startLevel);
  };

  const onStateChangeCallback = (key, value, updatedState, startLevel = 0) => {
    if (key === "disease") {
      //   getMappedForms(updatedState.disease);
      updatedState.form = null;
    }
    setState({ ...mappingstate, ...updatedState });
  };

  React.useEffect(() => {
    // getCountryMappedDiseases();
    // eslint-disable-next-line
  }, []);

  const getCountryMappedDiseases = () => {
    let sort = {
      sortField: "disease_name",
      sortDirection: "ASC",
    };

    dispatch(
      actions.GET_ALL_DISEASES({
        ...sort,
      })
    );
  };

  const getMappedForms = (disease) => {
    let diseaseId = disease?.value;
    dispatch(actions.GET_ALL_FORMS({ diseases: [diseaseId] }));
  };

  const onDeleteIconClicked = (index, mappingDetail) => {
    dialogContext.setDialog({
      ...dialogContext,
      open: true,
      title: Message.data_security_delete_title,
      body: Message.data_security_delete_message(mappingDetail),
      positiveBtn: Message.data_security_delete_btn,
      negativeBtn: "Cancel",
      onOk: () => deleteMappingTableRow(index),
    });
  };

  const deleteMappingTableRow = (index) => {
    dialogContext.setDialog({ ...dialogContext, open: false });
    let newRow = row;
    newRow.mappingTable.splice(index, 1);
    setRow({ ...row, ...newRow });
  };

  const addToMappedRow = () => {
    let newRow = row?.mappingTable ?? [];

    let isExist = newRow?.filter(
      (fr) =>
        fr.disease?.value === mappingstate.disease?.value &&
        fr.form?.value === mappingstate.form?.value
    );

    if (isExist?.length) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.warning,
        msg: "Mapping already added!!!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
      return true;
    }

    newRow?.push({ ...mappingstate });

    setRow({ ...row, mappingTable: newRow });

    let error = mappingstate.error;
    error.mappingTable = "";

    setState({ ...mappingstate, error });
  };

  React.useEffect(() => {
    props.updateState("data_security", row);
    // eslint-disable-next-line
  }, [row]);
  return (
    <Paper
      style={{
        width: "100%",
        padding: "10px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        "box-shadow": "0px 2px 4px #0000000A",
        "border-radius": "8px",
        opacity: 1,
      }}
      elevation="2"
    >
      <Grid container direction="row" alignItems="center" spacing={2}>
        {/* <Grid item xs={12}>
        <div style={{ display: "flex" }}>
          <Typography variant="body1">{""}</Typography>

        </div>
      </Grid> */}

        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="body1" color={"textSecondary"}>
            Mapped Diseases
          </Typography>
          <Select
            id="selectDisease"
            className="basic-single"
            classNamePrefix="select"
            // isLoading={mappedDiseaseData?.loading}
            isClearable
            isSearchable
            value={mappingstate.disease}
            placeholder="Select Disease"
            options={
              mappedDiseaseData ?? []
              //   mappedDiseaseData?.loading ? [] : mappedDiseaseData.data
            }
            onChange={(value) => changeState("disease", value)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="body1" color={"textSecondary"}>
            Mapped Forms
          </Typography>
          <Select
            id="selectForms"
            className="basic-single"
            classNamePrefix="select"
            // isLoading={mappedFormData?.loading}
            isClearable
            isSearchable
            value={mappingstate.form}
            placeholder="Select Forms"
            options={
              []
              //   mappedFormData?.loading ? [] : [...mappedFormData?.data] ?? []
            }
            onChange={(value) => changeState("form", value)}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          style={{ textAlign: "end", marginTop: 28 }}
        >
          <Button
            id="mapFormAndDisease"
            variant="text"
            className={classes.mapform}
            //   onClick={addToMappedRow}
            //   disabled={!mappingstate.disease || !mappingstate.form}
          >
            {"Map Form & Disease"}
          </Button>
        </Grid>

        {/* <Grid item xs={12} style={{ marginTop: 16, marginBottom: 16 }}>
        {mappingstate.error.mappingTable && (
          <Typography variant="caption" color="error">
            {mappingstate.state.error.mappingTable}
          </Typography>
        )}
        <MappingTable
          rows={row?.mappingTable}
          deleteMappingTableRow={onDeleteIconClicked}
        />
      </Grid> */}
      </Grid>
      <Grid item xs={12} style={{ marginTop: 16, marginBottom: 16 }}>
        {mappingstate.error.mappingTable && (
          <Typography variant="caption" color="error">
            {mappingstate.state.error.mappingTable}
          </Typography>
        )}
        <MappingTable
          rows={row?.mappingTable}
          deleteMappingTableRow={onDeleteIconClicked}
        />
      </Grid>
    </Paper>
  );
};

export const diseaseList = [
  {
    _id: "master_disease/10012",
    _key: "10012",
    _rev: "_evHLbGe---",
    activestatus: true,
    classification: {
      _key: "10115",
      _id: "CodingMaster/10115",
      _rev: "_eth1ku----",
      code: "Food and Water Borne Diseases",
      display: "Food and Water Borne Diseases",
      id: 0,
      Type: "Disease Classification",
      shortdesc: "Food and Water Borne Diseases",
      gmconfigvalues: {},
      status: true,
      createdby: "",
      createddate: 1662034352,
      updatedby: "",
      updatedate: 1662034352,
      activestatus: true,
    },
    createdby: "",
    createddate: 1662440334,
    disease_id: "",
    disease_name: "dk je",
    icd_10: [
      {
        _key: "10004",
        _id: "CodingMaster/10004",
        _rev: "_ethAcwe---",
        code: "A01.0",
        display: "A01.0",
        id: 0,
        Type: "ICD Code",
        shortdesc: "A01.0",
        gmconfigvalues: {},
        status: true,
        createdby: "",
        createddate: 1662030871,
        updatedby: "",
        updatedate: 1662030871,
        activestatus: true,
      },
      {
        _key: "10003",
        _id: "CodingMaster/10003",
        _rev: "_ethAPdK---",
        code: "A01",
        display: "A01",
        id: 0,
        Type: "ICD Code",
        shortdesc: "A01",
        gmconfigvalues: {},
        status: true,
        createdby: "",
        createddate: 1662030857,
        updatedby: "",
        updatedate: 1662030857,
        activestatus: true,
      },
    ],
    updatedate: 1662459409,
    updatedby: "",
  },
  {
    SourceSystemID: "sourceID",
    SourceSystemRefId: "sourceRefID",
    _id: "master_disease/10010",
    _key: "10010",
    _rev: "_evCT9LW---",
    activestatus: true,
    classification: {
      _key: "10119",
      _id: "CodingMaster/10119",
      _rev: "_eth2HkW---",
      code: "Sexually Transmitted Infections",
      display: "Sexually Transmitted Infections",
      id: 0,
      Type: "Disease Classification",
      shortdesc: "Sexually Transmitted Infections",
      gmconfigvalues: {},
      status: true,
      createdby: "",
      createddate: 1662034388,
      updatedby: "",
      updatedate: 1662034388,
      activestatus: true,
    },
    createdby: "",
    createddate: 1662438835,
    disease_id: "95959d50-bb8a-45b4-b6ee-0fa2712abca3",
    disease_name: "Early syphillis",
    icd_10: [
      {
        _key: "10012",
        _id: "CodingMaster/10012",
        _rev: "_ethD2Yq---",
        code: "A05.1",
        display: "A05.1",
        id: 0,
        Type: "ICD Code",
        shortdesc: "A05.1",
        gmconfigvalues: {},
        status: true,
        createdby: "",
        createddate: 1662031094,
        updatedby: "",
        updatedate: 1662031094,
        activestatus: true,
      },
      {
        _key: "10002",
        _id: "CodingMaster/10002",
        _rev: "_eth_0lW---",
        code: "A00",
        display: "A00",
        id: 0,
        Type: "ICD Code",
        shortdesc: "A00",
        gmconfigvalues: {},
        status: true,
        createdby: "",
        createddate: 1662030830,
        updatedby: "",
        updatedate: 1662030830,
        activestatus: true,
      },
    ],
    updatedate: 1662438835,
    updatedby: "",
  },
  {
    _id: "master_disease/10021",
    _key: "10021",
    _rev: "_evHwn6q---",
    activestatus: true,
    classification: {
      _key: "10116",
      _id: "CodingMaster/10116",
      _rev: "_eth1uVO---",
      code: "Hemorrhagic Fevers",
      display: "Hemorrhagic Fevers",
      id: 0,
      Type: "Disease Classification",
      shortdesc: "Hemorrhagic Fevers",
      gmconfigvalues: {},
      status: true,
      createdby: "",
      createddate: 1662034362,
      updatedby: "",
      updatedate: 1662034362,
      activestatus: true,
    },
    createdby: "",
    createddate: 1662461847,
    disease_id: "",
    disease_name: "ecjnece",
    icd_10: [
      {
        _key: "10004",
        _id: "CodingMaster/10004",
        _rev: "_ethAcwe---",
        code: "A01.0",
        display: "A01.0",
        id: 0,
        Type: "ICD Code",
        shortdesc: "A01.0",
        gmconfigvalues: {},
        status: true,
        createdby: "",
        createddate: 1662030871,
        updatedby: "",
        updatedate: 1662030871,
        activestatus: true,
      },
    ],
    updatedate: 1662461847,
    updatedby: "",
  },
  {
    _id: "master_disease/10028",
    _key: "10028",
    _rev: "_evMyGFm---",
    activestatus: true,
    classification: {
      _key: "10114",
      _id: "CodingMaster/10114",
      _rev: "_eth1RR6---",
      code: "Coronavirus",
      display: "Coronavirus",
      id: 0,
      Type: "Disease Classification",
      shortdesc: "Coronavirus",
      gmconfigvalues: {},
      status: true,
      createdby: "",
      createddate: 1662034332,
      updatedby: "",
      updatedate: 1662034332,
      activestatus: true,
    },
    createdby: "",
    createddate: 1662482879,
    disease_id: "",
    disease_name: "feifvenk",
    icd_10: [
      {
        _key: "10002",
        _id: "CodingMaster/10002",
        _rev: "_eth_0lW---",
        code: "A00",
        display: "A00",
        id: 0,
        Type: "ICD Code",
        shortdesc: "A00",
        gmconfigvalues: {},
        status: true,
        createdby: "",
        createddate: 1662030830,
        updatedby: "",
        updatedate: 1662030830,
        activestatus: true,
      },
    ],
    updatedate: 1662482915,
    updatedby: "",
  },
  {
    _id: "master_disease/10027",
    _key: "10027",
    _rev: "_evH5Z0u---",
    activestatus: true,
    classification: {
      _key: "10115",
      _id: "CodingMaster/10115",
      _rev: "_eth1ku----",
      code: "Food and Water Borne Diseases",
      display: "Food and Water Borne Diseases",
      id: 0,
      Type: "Disease Classification",
      shortdesc: "Food and Water Borne Diseases",
      gmconfigvalues: {},
      status: true,
      createdby: "",
      createddate: 1662034352,
      updatedby: "",
      updatedate: 1662034352,
      activestatus: true,
    },
    createdby: "",
    createddate: 1662462422,
    disease_id: "",
    disease_name: "jdw",
    icd_10: [
      {
        _key: "10005",
        _id: "CodingMaster/10005",
        _rev: "_ethAuUu---",
        code: "A01.1",
        display: "A01.1",
        id: 0,
        Type: "ICD Code",
        shortdesc: "A01.1",
        gmconfigvalues: {},
        status: true,
        createdby: "",
        createddate: 1662030889,
        updatedby: "",
        updatedate: 1662030889,
        activestatus: true,
      },
    ],
    updatedate: 1662462422,
    updatedby: "",
  },
  {
    _id: "master_disease/10022",
    _key: "10022",
    _rev: "_evHw9n----",
    activestatus: true,
    classification: {
      _key: "10113",
      _id: "CodingMaster/10113",
      _rev: "_eth1KWu---",
      code: "AMR",
      display: "AMR",
      id: 0,
      Type: "Disease Classification",
      shortdesc: "AMR",
      gmconfigvalues: {},
      status: true,
      createdby: "",
      createddate: 1662034325,
      updatedby: "",
      updatedate: 1662034325,
      activestatus: true,
    },
    createdby: "",
    createddate: 1662461869,
    disease_id: "",
    disease_name: "koko",
    icd_10: [
      {
        _key: "10002",
        _id: "CodingMaster/10002",
        _rev: "_eth_0lW---",
        code: "A00",
        display: "A00",
        id: 0,
        Type: "ICD Code",
        shortdesc: "A00",
        gmconfigvalues: {},
        status: true,
        createdby: "",
        createddate: 1662030830,
        updatedby: "",
        updatedate: 1662030830,
        activestatus: true,
      },
    ],
    updatedate: 1662461869,
    updatedby: "",
  },
  {
    _id: "master_disease/10013",
    _key: "10013",
    _rev: "_evHjmBG---",
    activestatus: true,
    classification: {
      _key: "10113",
      _id: "CodingMaster/10113",
      _rev: "_eth1KWu---",
      code: "AMR",
      display: "AMR",
      id: 0,
      Type: "Disease Classification",
      shortdesc: "AMR",
      gmconfigvalues: {},
      status: true,
      createdby: "",
      createddate: 1662034325,
      updatedby: "",
      updatedate: 1662034325,
      activestatus: true,
    },
    createdby: "",
    createddate: 1662453659,
    disease_id: "",
    disease_name: "venkatesh",
    icd_10: [
      {
        _key: "10002",
        _id: "CodingMaster/10002",
        _rev: "_eth_0lW---",
        code: "A00",
        display: "A00",
        id: 0,
        Type: "ICD Code",
        shortdesc: "A00",
        gmconfigvalues: {},
        status: true,
        createdby: "",
        createddate: 1662030830,
        updatedby: "",
        updatedate: 1662030830,
        activestatus: true,
      },
      {
        _key: "10004",
        _id: "CodingMaster/10004",
        _rev: "_ethAcwe---",
        code: "A01.0",
        display: "A01.0",
        id: 0,
        Type: "ICD Code",
        shortdesc: "A01.0",
        gmconfigvalues: {},
        status: true,
        createdby: "",
        createddate: 1662030871,
        updatedby: "",
        updatedate: 1662030871,
        activestatus: true,
      },
    ],
    updatedate: 1662460993,
    updatedby: "",
  },
];
