/**
 * @author Hari Ram
 * @email hariram@qdmplatforms.com
 * @create date 2021-01-28
 * @modify date 2021-01-29
 * @desc Input component
 */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  date: {
    "& fieldset": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      // borderRight:'px solid',
      borderColor: (props) =>
        props?.errorValidation?.error ? "red" : "rgba(0, 0, 0, 0.23)",
    },
  },
  textField: {
    "& fieldset": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderLeft: 0,
      borderColor: (props) =>
        props?.errorValidation?.error ? "red" : "rgba(0, 0, 0, 0.23)",
    },
    "& input": {
      backgroundColor: "rgb(245, 245, 245)",
    },
  },
  labelStyle: {
    fontSize: 16,
  },
  errorText: {
    color: "red",
    fontSize: "0.75rem",
  },
}));

const DOB = (props) => {
  const classes = useStyles(props);

  const getLabel = (props) => {
    return (
      <Typography variant="body1" style={{ fontSize: 16 }} gutterBottom>
        {props.label}{" "}
        {props.isrequired && (
          <Typography variant="caption" style={{ color: "red" }}>
            *
          </Typography>
        )}
      </Typography>
    );
  };

  const maximumDate = () => {
    return moment(new Date()).format("YYYY-MM-DD");
  };

  const [dob, setDob] = React.useState({
    age: 0,
    periods: "year",
  });

  const findAge = (value) => {
    let age;
    let years = moment().diff(value, "years");

    if (years === 0) {
      let months = moment().diff(value, "months");
      if (months === 0) {
        let days = moment().diff(value, "days");
        age = parseFloat("0.0" + days);
        setDob({ age: days, periods: "day" });
      } else {
        age = parseFloat("0." + months);
        setDob({ age: months, periods: "month" });
      }
    } else {
      age = years;
      setDob({ age: years, periods: "year" });
    }

    props.onChange({
      dob: value,
      age,
    });
  };

  React.useEffect(() => {
    if (props?.value?.dob) {
      findAge(props?.value?.dob);
    }
    // eslint-disable-next-line
  }, [props?.value?.dob]);

  return (
    <div id={props.id ?? ""}>
      {getLabel(props)}
      <Grid container>
        <Grid item xs={6} md={6}>
          <TextField
            //onKeyPress={(e) => e.preventDefault()}
            id={props?.id ? props?.id + "Date" : "date"}
            fullWidth
            type={"date"}
            value={props?.value?.dob}
            className={classes.date}
            InputLabelProps={{
              shrink: false,
            }}
            inputProps={{
              readOnly: props.isReadonly ?? false,
              max: maximumDate(),
            }}
            size="small"
            onChange={(e) => findAge(e.target.value)}
            disabled={props?.disabled ?? false}
            variant={"outlined"}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextField
            id={props?.id ? props?.id + "Age" : "Age"}
            type={"text"}
            required={props.isrequired}
            variant={"outlined"}
            fullWidth
            className={classes.textField}
            InputLabelProps={{
              shrink: false,
            }}
            inputProps={{ readOnly: true }}
            size="small"
            disabled={true}
            placeholder="Age"
            // onChange={(e) => props.onChange(e.target.value)}
            value={`${
              dob?.age >= 0
                ? dob?.age > 1
                  ? dob?.age + " " + dob?.periods + "s old"
                  : dob?.age + " " + dob?.periods + " old"
                : ""
            }`}
          />
        </Grid>
      </Grid>
      {props?.errorValidation && props?.errorValidation?.error && (
        <div>
          <Typography variant="caption" className={classes.errorText}>
            {props?.errorValidation?.errorMessage}
          </Typography>
        </div>
      )}
    </div>
  );
};
DOB.propTypes = {
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  //   variant: PropTypes.oneOf(["Standard" | "filled" | "outlined"]),
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  isReadonly: PropTypes.bool,
};
DOB.defaultProps = {
  label: "label",
  //   variant: "outlined",
  defaultValue: null,
  type: "date",
};
export default DOB;
