import {
  Grid,
  IconButton,
  List,
  makeStyles,
  Popover,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Edit, Delete, MoreVert, Add } from "@material-ui/icons";
import React from "react";
import { Message } from "../../utils";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { AlertProps } from "../../utils/constants";
import { AlertContext, BackdropContext, DialogContext } from "../../contexts";
import { actions, LevelApi } from "surveillance-binder";
import { useDispatch } from "react-redux";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { NotFoundSection } from "../../components";

// Store Action's list
const { UPSERT_MANAGE_LOCATION_LEVEL_CHILD } = actions;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    borderRight: `1px solid ${theme.palette.grey[400]}`,
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    borderTop: `1px solid ${theme.palette.grey[400]}`,
    // borderLeft: `1px solid ${theme.palette.grey[400]}`,
    borderLeft: `1px solid ${theme.palette.grey[400]}`,
  },
  header: {
    paddingLeft: theme.spacing(1.5),
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  searchBarParent: {
    padding: theme.spacing(1),
  },
  searchField: {
    height: 30,
  },
  addChildBtnParent: {
    textAlign: "end",
  },
  addChildBtn: {},
  listItemIcon: {
    minWidth: 35,
  },
  rootTwo: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
    height: "calc(100% - 50px)",
  },
  rootTwoList: {
    // overflowY: 'auto'
  },
}));

export const LevelDetail = ({
  structure = "",
  level = "",
  level_id = "",
  level_name = "",
  parent_child_level_id = "",
  // child_level_id = '',
  countryId = "",
  onEditLevelClicked = () => false,
  handleAddBtnClick = () => false,
  handleAddBtnFirstClick = () => false,
  child = [],
  state = {},
  selected = "",
  setSelected = "",
  data = [],
  levelNames = [],
  getManageLocation = () => false,
  superFunction = () => false,
  disabledadd1 = () => true,
  disabledadd2 = () => false,
  disabledmore = () => false,
}) => {
  const classes = useStyles();
  levelNames.push(level_name);

  let previousLevel = levelNames.filter(function (item, index, inputArray) {
    return inputArray.indexOf(item) === index;
  });

  // Declaring Store dispatch
  const dispatch = useDispatch();
  const alert = React.useContext(AlertContext);

  const backdrop = React.useContext(BackdropContext);
  const dialogContext = React.useContext(DialogContext);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMoreBtnClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreBtnClose = () => {
    setAnchorEl(null);
  };

  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const deleteItem = (item) => {
    ;
    let Name = item?.child_name;

    dialogContext.setDialog({ ...dialogContext, open: false });

    let __params = {
      isActive: false,
      child_level_id: item.child_level_id,
      level: level,
      _key: item?._key,
      rowdetails: item,
    };

    Promise.resolve(
      dispatch(UPSERT_MANAGE_LOCATION_LEVEL_CHILD(__params))
    ).then((res) => {
      // clear upcoming level when we delete current level
      superFunction(level, {});

      backdrop.setBackDrop({ ...backdrop, open: false });
      if (!res.payload.error) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: Name + " Deleted successfully !",
        });

        getManageLocation();
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something went wrong, Try Again!",
        });
      }
    });
  };

  const open = Boolean(anchorEl);

  const id = open ? "more-btn-popover" : undefined;

  const [textfielderr, setTextfield] = React.useState(false);
  const [errhelper, setErrhelper] = React.useState("");
  const blur = () => {
    if (inputValue.trim().length === 0) {
      setTextfield(true);
      setErrhelper("Fields can't be Empty!");
    } else {
      setToggleInput({
        ...toggleInput,
        toggle: false,
      });
      setInputValue("");
      getManageLocation();
      setTextfield(false);
      setErrhelper("");
    }
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      blur();
    }
  };

  const [toggleInput, setToggleInput] = React.useState({
    index: "",
    toggle: false,
  });

  const [inputValue, setInputValue] = React.useState("");

  const clickColumnName = (item, index) => {
    setToggleInput({
      toggle: true,
      index,
    });
    setInputValue(item.child_name);
  };

  const onChangeColumnName = (value, index, item, level) => {
    ;
    setInputValue(value);
    let __params = {
      _key: item?._key,
      countryId: item?.countryId,
      childs: [value].map((obj) => ({
        ...obj,
        value: value,
        child_level_id: item?.child_level_id,
        isActive: true,
      })),
      level_id: item?.level_id,
      parent_child_level_id: item?.parent_child_level_id,
      parent: item?.parent,
      level: level,
      isActive: item?.is_active,
      rowdetails: item,
    };

    Promise.resolve(
      dispatch(UPSERT_MANAGE_LOCATION_LEVEL_CHILD(__params))
    ).then((res) => {
      backdrop.setBackDrop({ ...backdrop, open: false });
    });
  };

  const onDeleteIconClicked = async (item) => {
    let LevelReference = await LevelApi.levelDataDelete({
      levelData: item._id,
      levelStructure: structure,
    });
    if (LevelReference.Code === 201) {
      dialogContext.setDialog({
        ...dialogContext,
        open: true,
        title:
          "Are you sure you want to delete " +
          item?.child_name +
          " (" +
          level_name +
          ") ?",
        body: "",
        positiveBtn: Message.country_delete_btn,
        negativeBtn: "Cancel",
        onOk: () => deleteItem(item),
      });
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: LevelReference.error,
      });
    }
  };

  const arrowclicked = (indexNo, item) => {
    superFunction(indexNo, item);

    setSelected(item);
  };

  const callArrowAndListItem = (event, index, level, item) => {
    handleListItemClick(event, index);
    arrowclicked(level, item);
  };
  return (
    <div className={classes.root}>
      {/* Header */}
      <Grid
        className={classes.header}
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant={"body1"}>{level_name}</Typography>
        </Grid>

        <Grid item>
          {child.length >= 0 && level === 1 ? (
            <IconButton
              id={`${level_name + "AddButton"}`}
              onClick={() =>
                handleAddBtnFirstClick(level, level_id, level_name, countryId)
              }
              disabled={disabledadd1?.false}
            >
              <Add color={"primary"} />
            </IconButton>
          ) : child.length === 0 ? (
            <></>
          ) : (
            <IconButton
              id={`${level_name + "AddButton"}`}
              onClick={() => {
                handleAddBtnClick(selected, level, level_name, child.length);
              }}
              disabled={disabledadd2?.false}
            >
              <Add color={"primary"} />
            </IconButton>
          )}

          <IconButton
            onClick={handleMoreBtnClick}
            id={`${level_name + "MoreButton"}`}
            disabled={disabledmore?.false}
          >
            <MoreVert />
          </IconButton>
        </Grid>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleMoreBtnClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <List dense>
            {/* Edit Icon */}
            <ListItem
              id={`${level_name + "EditButton"}`}
              dense
              button
              onClick={() => {
                handleMoreBtnClose();
                onEditLevelClicked();
              }}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <Edit color={"primary"} />
              </ListItemIcon>
              <ListItemText primary="Edit" />
            </ListItem>

            {/* Delete Icon */}
            <ListItem
              id={`${level_name + "DeleteButton"}`}
              dense
              button
              onClick={() => {
                handleMoreBtnClose();
                onEditLevelClicked();
              }}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <Delete color={"primary"} />
              </ListItemIcon>
              <ListItemText primary="Delete" />
            </ListItem>
          </List>
        </Popover>
      </Grid>

      <div
        className={classes.rootTwo}
        // style={{ overflow: "auto", height: "100%" }}
      >
        <List
          component="nav"
          aria-label="secondary mailbox folder"
          style={{
            height: "100%",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {child?.map((item, index) => {
            return (
              <ListItem
                id={"listItem" + index}
                button
                selected={selectedIndex === index}
                onClick={(event) => {
                  callArrowAndListItem(event, index, level, item);
                }}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {/* <ListItemText primary={item?.child_name} /> */}

                <Tooltip title="Double click to edit" placement="right-start">
                  <Typography
                    variant="body2"
                    onClick={() => clickColumnName(item, index)}
                  >
                    {toggleInput.toggle && toggleInput.index === index ? (
                      <TextField
                        id={inputValue}
                        autoFocus
                        value={inputValue}
                        onChange={(e) =>
                          onChangeColumnName(e.target.value, index, item, level)
                        }
                        onBlur={blur}
                        onKeyPress={(e) => handleEnter(e)}
                        style={{ width: "160px" }}
                        error={textfielderr}
                        helperText={errhelper}
                      />
                    ) : (
                      item?.child_name
                    )}
                  </Typography>
                </Tooltip>
                <Grid style={{ display: "flex" }}>
                  <IconButton
                    id={"deleteButton" + index}
                    onClick={() => {
                      onDeleteIconClicked(item);
                    }}
                  >
                    <DeleteOutlineOutlinedIcon color="error" />
                  </IconButton>

                  <IconButton
                    id={"ArrowButton" + index}
                    onClick={() => {
                      arrowclicked(level, item);
                    }}
                  >
                    <ArrowRightAltIcon />
                  </IconButton>
                </Grid>
              </ListItem>
            );
          })}

          {child.length === 0 && (
            <NotFoundSection
              // eslint-disable-next-line
              message={
                level === 1
                  ? "Click Add " + level_name + " Button to add a " + level_name
                  : state[`selected_level_${level - 1}`]?.child_name
                  ? "You can add " +
                    level_name +
                    " to  " +
                    state[`selected_level_${level - 1}`]?.child_name
                  : " Click a " +
                    previousLevel[level - 2] +
                    "  to view / add " +
                    level_name +
                    "(s)"
              }
              showActionBtn={
                state[`selected_level_${level - 1}`]?.child_name ? true : false
              }
              actionBtnName={"Add " + level_name}
              onActionButtonClicked={() =>
                handleAddBtnClick(selected, level, level_name)
              }
            />
          )}
        </List>
      </div>
    </div>
  );
};
