import React, { useState } from "react";
import { ManageCard } from "atp-crud";
import {
  AlertContext,
  BackdropContext,
  DialogContext,
  DrawerContext,
} from "../../contexts";
import { AddEditDisease } from "../../components/managedisease/addEditDiseaseListing";
import { withNavBars } from "../../HOCs";
import { Message } from "../../utils";
import { connect, useDispatch, useSelector } from "react-redux";
import { actions, idmapis } from "surveillance-binder";
import { AlertProps, DrawerProps } from "../../utils/constants";
import { withTheme } from "@material-ui/core";

function ManageDisease(props) {
  //console.log({ props });
  const dispatch = useDispatch();
  const [delay, setDelay] = React.useState(false);
  const getallselector = useSelector((state) => ({
    getAllDiseases: state?.DiseaseSlice?.getAllDiseases,
    getAllICD10Code: state?.DiseaseSlice?.getAllICD10Code,
    getAllDiseasesClassification:
      state?.DiseaseSlice?.getAllDiseasesClassification,
  }));
  // const getallselector = useSelector((state) => state.GET_ALL_DISEASES);

  //console.log("Disease", getallselector.getAllDiseases.data);
  React.useLayoutEffect(() => {
    (async function () {
      let roleid = localStorage.getItem("RoleId");
      let roletype = await idmapis.getuserrole_from_permissionrolemapping(
        roleid
      );
      let issuperadmin =
        roletype?.result[0].rolename ===
        process.env.REACT_APP_SUPERADMIN_KEYNAME;
      setISSuperAdmin(issuperadmin);
      localStorage.setItem("issuperadmin", issuperadmin ? 1 : "");
      let vms = {
        dbname: process.env.REACT_APP_DB,
        entity: "master_disease",
        sortkey: "disease_name",
        isSuperAdmin: issuperadmin,
        tenantid: localStorage.getItem("tenentid"),
      };
      let vdc = {
        dbname: process.env.REACT_APP_DB,
        entity: "CodeableConceptMaster",
        // sortkey: "disease_name",
      };
      let vicd = {
        dbname: process.env.REACT_APP_DB,
        entity: "CodeableConceptMaster",
        sortkey: "icd_10_id",
      };
      await dispatch(actions.GET_ALL_DISEASES(vms));
      dispatch(actions.GET_ALL_DISEASES_CLASSIFICATION(vdc));
      dispatch(actions.GetAllICD10Code(vicd));
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: "Loading Data",
      });
    })();
    setTimeout(() => {
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });
      setDelay(true);
    }, 2000);
  }, []);
  const drawer = React.useContext(DrawerContext);
  const dialogContext = React.useContext(DialogContext);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);

  const [data, setData] = useState();
  const [filterdata, setfilteredata] = useState();
  const [issuperAdmin, setISSuperAdmin] = useState(false);
  const onDrawerClosed = () => {
    drawer.setDrawer({
      ...drawer,
      open: false,
      direction: DrawerProps.direction.right,
    });
  };
  const onActionCompleted = () => {
    onDrawerClosed();
    // props.getDiseasesList(
    //   document.getElementById("ippc_search_bar").value,
    //   true
    // );
    let vms = {
      dbname: process.env.REACT_APP_DB,
      entity: "master_disease",
      sortkey: "disease_name",
      isSuperAdmin: Boolean(localStorage.getItem("issuperadmin")),
      tenantid: localStorage.getItem("tenentid"),
    };
    dispatch(actions.GET_ALL_DISEASES(vms));
  };
  const editfunc = (value) => {
    //console.log("value", value);
    drawer.setDrawer({
      open: true,
    });
    drawer.setDrawer({
      open: true,
      component: (
        <AddEditDisease
          data={getallselector.getAllICD10Code?.data?.result ?? []}
          dataClass={
            getallselector.getAllDiseasesClassification?.data?.result ?? []
          }
          onActionCompleted={onActionCompleted}
          editData={value?.currentmapobject}
          isEdit={true}
        />
      ),
    });
  };
  const deletefunc = (value) => {
    //console.log("deleval", value);
    dialogContext.setDialog({
      ...dialogContext,
      open: true,
      title: Message.disease_delete_title,
      body: Message.disease_delete_message,
      positiveBtn: Message.disease_delete_btn,
      negativeBtn: "Cancel",
      onOk: () => deleteDisease(value?.currentmapobject),
    });
  };
  const addfunc = () => {
    drawer.setDrawer({
      open: true,
    });
    drawer.setDrawer({
      open: true,
      component: (
        <AddEditDisease
          data={getallselector.getAllICD10Code?.data?.result ?? []}
          dataClass={
            getallselector.getAllDiseasesClassification?.data?.result ?? []
          }
          onActionCompleted={onActionCompleted}
        />
      ),
    });
  };

  const filterfunc = () => {
    alert("hi");
  };
  const sortfunc = () => {
    alert("hi");
  };
  const searchfunc = (value) => {
    //console.log({ value });
    // if (Boolean(value)) {
    //   debugger;
    //   var finalfilteredvalue = data.filter((v) => {
    //     return (
    //       v?.email_id?.toLowerCase().includes(value?.toLowerCase()) ||
    //       v?.first_name?.toLowerCase().includes(value?.toLowerCase())
    //     );
    //   });
    //   setfilteredata(finalfilteredvalue);
    // } else {
    //   setfilteredata(data);
    // }
  };
  const deleteDisease = async (data) => {
    dialogContext.setDialog({ ...dialogContext, open: false });
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: Message.disease_delete,
    });

    let apidata = {
      dbname: process.env.REACT_APP_DB,
      entity: "master_disease",
      ismeta: true,
      metadataid: process.env.REACT_APP_METADATAID,
      metadatadbname: process.env.REACT_APP_METADATA_DB_NAME,
      filter: { _id: `${data._id}` },
      doc: {
        activestatus: false,
      },
    };
    await backdrop.setBackDrop({ ...backdrop, open: false });
    Promise.resolve(dispatch(actions.AddDisease(apidata))).then((res) => {
      let vms = {
        dbname: process.env.REACT_APP_DB,
        entity: "master_disease",
        sortkey: "disease_name",
        isSuperAdmin: Boolean(localStorage.getItem("issuperadmin")),
        tenantid: localStorage.getItem("tenentid"),
      };

      dispatch(actions.GET_ALL_DISEASES(vms));
      let resStatus = res.payload.error;
      alert.setSnack({
        ...alert,
        open: true,
        severity: resStatus
          ? AlertProps.severity.error
          : AlertProps.severity.success,
        msg: resStatus
          ? "Disease deletion Failed !"
          : "Disease deleted Successfully !",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    });
  };
  return (
    <div>
      {delay && (
        <ManageCard
          basedata={getallselector?.getAllDiseases?.data?.result ?? []}
          globalsrc={"/images/virus.svg"}
          title={"Manage Disease"}
          topbuttonName={"Add Disease"}
          hideFilter={true}
          showSort={false}
          sortOptions={[
            { label: "name", value: "name" },
            { label: "age", value: "age" },
          ]}
          editfunc={editfunc}
          addfunc={addfunc}
          sortfunc={sortfunc}
          filterfunc={filterfunc}
          searchfunc={searchfunc}
          isMoreBtn={issuperAdmin}
          hideAvatar={false}
          hideEditBtn={false}
          hideDeleteBtn={false}
          divider={true}
          isFilterApplied={false}
          keysformap={{
            // src_key: "first_name",
            // alt_key: "alt",
            title_key: "disease_name",
            cardname_key: "classification.display",
            subtitle_key: "icd_10",
            // url_key: "desigination",
            // email_key: "email_id",
          }}
          hidebtn2={true}
          onBtn2Clicked={() => alert("ji")}
          Btn2Name={"btn1"}
          hidebtn1={true}
          onBtn1Clicked={() => alert("ji")}
          Btn1Name={"okoo"}
          iconButton={false}
          onActionIconBtnClicked={() => alert("null")}
          show_card={true}
          show_sub_title={true}
          deletefunc={deletefunc}
          inbuiltsearchfunctinality={true}
          hideSearchBar={true}
          hideaddbutton={!issuperAdmin}
          // theme={require("../../themes/default.json")}
          theme={props?.theme}
        />
      )}
    </div>
  );
}
const mapStateToProps = (state) => ({
  getAllDiseases: state?.DiseaseSlice?.getAllDiseases,
  getAllICD10Code: state?.DiseaseSlice?.getAllICD10Code,
  getAllDiseasesClassification:
    state?.DiseaseSlice?.getAllDiseasesClassification,
});
export default connect(
  mapStateToProps,
  actions
)(withNavBars(withTheme(ManageDisease)));
