import React from "react";
import { withNavBars } from "../../HOCs";
import { Typography, makeStyles } from "@material-ui/core";
import ChartLayout from "./chartLayout";
import ClientDashBoard from "./clientDashBoard";
import { AxiosCall } from "../../utils";
import { checkWithIdm } from "atp-casbin-js";
import { LoadingScreen } from "../../components/loadingScreen";
import NotifierDashBoard from "./notifierDashboard";
import VerifierDashboard from "./verifierDashBoard";
import { idmapis } from "surveillance-binder";
import { useCheckWithIDM } from "./hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    padding: theme.spacing(2),
    width: "100%",
    height: "100%",
    background: theme.palette.background.main,
    overflowY: "auto",
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  // const [loading, setLoading] = React.useState(true);
  const ViewClientDashBoard = useCheckWithIDM("Client Dashboard");
  const ViewNotifierDashBoard = useCheckWithIDM("Notifier Dashboard");
  const ViewVerifierDashBoard = useCheckWithIDM("Verifier Dashboard");

  // React.useLayoutEffect(() => {
  //   // const checkSuperAdmin = async () => {
  //   //   let roleid = localStorage.getItem("RoleId");
  //   //   let roletype = await idmapis.getuserrole_from_permissionrolemapping(
  //   //     roleid
  //   //   );
  //   //   let issuperadmin =
  //   //     roletype?.result[0].rolename ===
  //   //     process.env.REACT_APP_SUPERADMIN_KEYNAME;
  //   //   if (issuperadmin) {
  //   //     setSuperAdminView(false)
  //   //     setLoading(false);
  //   //   } else {
  //   //     setLoading(false);
  //   //   }
  //   // };
  //   // checkSuperAdmin();
  // }, []);

  return (
    <>
      {/* {loading ? (
        <LoadingScreen />
      ) : ( */}
      <div className={classes.root}>
        {ViewClientDashBoard && <ClientDashBoard />}
        {ViewNotifierDashBoard && <NotifierDashBoard />}
        {ViewVerifierDashBoard && <VerifierDashboard />}
        {!ViewClientDashBoard &&
          !ViewNotifierDashBoard &&
          !ViewVerifierDashBoard && (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h3" align="center">
                Welcome to <br />
                Surveillance Platform
              </Typography>
            </div>
          )}
      </div>
      {/* )} */}
    </>
  );
};

export default withNavBars(Dashboard);
