import { Paper } from "@material-ui/core";

import React, { Component } from "react";
import Config from "../../config";

class TaskAndDocument extends Component {
  render() {
    const { tenantid, clientId } = this.props;
    return (
      <>
        <Paper
          style={{
            width: "100%",
            height: "100%",
            padding: "4px",
          }}
        >
          <iframe
            title="Task And Document"
            src={`${Config.taskanddocument}&token=${localStorage.getItem(
              "access_token"
            )}${tenantid && `&tenantid=${tenantid}`}${
              clientId && `&clientid=${clientId}`
            }`}
            style={{
              width: "100%",
              height: "100%",
              outline: "none",
              border: "none",
            }}
          ></iframe>
        </Paper>
      </>
    );
  }
}

export default TaskAndDocument;
