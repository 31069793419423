import React from "react";
import { withStyles } from "@material-ui/core";
import LocationLevel from "./locationLevel";
import { actions } from "surveillance-binder";
import { connect } from "react-redux";

const styles = (theme) => ({
  root: {
    flex: 1,
  },
});
class LocationLevelParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getAllCountries();
  }

  getAllCountries = () => {
    this.props.GET_MANAGE_LEVELS({
      client_id: "client/10000",
    });
  };

  render() {
    const {
      classes,
      getAllCountries,
      label,
      isReadonly,
      isrequired,
      handleChange,
      value,
      errorValidation,
      disabled,
      id,
      primeLocation,
    } = this.props;
    return (
      <div className={classes.root}>
        {!getAllCountries.loading && !getAllCountries.error && (
          <LocationLevel
            id={id}
            primeLocation={primeLocation}
            countryData={getAllCountries}
            label={label}
            value={value}
            handleChange={handleChange}
            isReadonly={isReadonly}
            isrequired={isrequired}
            errorValidation={errorValidation}
            disabled={disabled}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  //   getAllCountries: state?.countrySlice?.getAllCountries,
  getAllCountries: state?.manageLevelSlice?.manageLevels,
});

export default connect(
  mapStateToProps,
  actions
)(withStyles(styles)(LocationLevelParent));
