import React from 'react'
import { Tabs,Tab} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: '0.5px solid #D6D9E7',
    backgroundColor:"#F0F0FA",
    overflowX:'auto'
  },
  rowView: {
    display: 'flex',
    // flexWrap: 'wrap',
  },
  buttonView: {
    cursor: 'pointer',
    marginRight: 20,
    marginLeft: 10,
    borderRadius: 2,
    paddingBottom: 15
  },
  grow: {
    flex: 1,
  },
  bottomSpacing: {
    padding: '17px 6px 2px 5px',
    fontSize: 17
  },
  individualTab:{
    textTransform:'capitalize',
  }
}))

export const TextRouter = props => {

  // const [value, setValue] = React.useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  // function a11yProps(index) {
  //   return {
  //     id: `simple-tab-${index}`,
  //     'aria-controls': `simple-tabpanel-${index}`,
  //   };
  // }

  const classes = useStyles(props);

  const history = useHistory();

  const giveMeSelectedTab = () => {
    let id = "";
    props.data && props.data.map((nav) => {
      if (window.location.pathname === nav.url) {
        id = nav.id;
      }
      return nav;
    });
    return id;
  };



  return (
    <div className={`${classes.root}`}>

        <div className={`${classes.rowView} ${classes.grow}`}>

          <Tabs 
              value={giveMeSelectedTab()} 
              selectionFollowsFocus={true}
              scrollButtons="auto"
              variant="scrollable"
              >
              {
                props.data && props.data.map((_data,index)=>{
                  return(
                    <Tab 
                      id={_data.id} 
                      key={_data.id}  
                      value={_data.id}
                      label={_data.name}
                      className={classes.individualTab}
                      onClick={() =>_data.url && history.push({ pathname: _data.url })}
                      />
                  ) 
                })
              }
            </Tabs>

        </div>

    </div>
  )
}

TextRouter.propTypes = {
  data: PropTypes.array,
  search: PropTypes.bool
}
