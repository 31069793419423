import React from "react";

import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Box, Grid } from "@material-ui/core";

import { Chart, registerables, ArcElement, Tooltip, Legend } from "chart.js";
Chart.register(...registerables, ArcElement, Tooltip, Legend, ChartDataLabels);

const DoughnutChart = (props) => {
  const { gridItem, dataSet } = props;
  const [state, setState] = React.useState(dataSet);

  React.useEffect(() => {
    if (dataSet) {
      console.log(dataSet);
      setState(dataSet);
    }
  }, [dataSet]);

  // const data = {
  //   name: "NOTIFIER",
  //   labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  //   datasets: [
  //     {
  //       label: "STATE",
  //       data: [12, 19, 3, 5, 2, 3],
  //       backgroundColor: [
  //         "rgba(255, 99, 132)",
  //         "rgba(54, 162, 235)",
  //         "rgba(255, 206, 86)",
  //         "rgba(75, 192, 192)",
  //         "rgba(153, 102, 255)",
  //         "rgba(255, 159, 64)",
  //       ],
  //       borderWidth: 8,
  //       borderColor: "#FFFFFF",
  //       hoverBorderColor: "#FFFFFF",
  //       hoverOffset: 8,
  //     },
  //   ],
  // };

  const options = {
    maintainAspectRatio: true,
    responsive: true,
    legend: false,
    cutout: "50%",
    plugins: {
      datalabels: {
        display: true,
        color: "white",
      },
      labels: {
        title: {
          font: {
            weight: "bold",
          },
        },
        value: {
          color: "green",
        },
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };
  return (
    <Box
      sx={{
        height: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid item xs={gridItem} lg={gridItem} xl={gridItem}>
        <Doughnut data={state} options={options} />
      </Grid>
    </Box>
  );
};

export default DoughnutChart;
