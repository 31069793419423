import {
  Grid,
  TextField,
  makeStyles,
  Typography,
  Divider,
  Button,
} from "@material-ui/core";
import React from "react";
import { MuiSelect } from "../../../../components/muiSelect/MuiSelect";
import { Message } from "../../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "99%",
  },
  girdItem: {
    width: "100%",
  },
  header: {
    padding: "10px 20px",
    color: theme.palette.primary.main,
  },
  formNameParent: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  countryParent: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  btnParent: {
    height: "52px",
    background: "#ffffff",
    // background: theme.palette.background.paper,
    padding: theme.spacing(1),
  },
  marginR: {
    marginRight: theme.spacing(2),
  },
}));
export const AddAddressInput = (props) => {
  const classes = useStyles();

  const { Use, index } = props;

  const [state, setState] = React.useState({
    type: props.isEdit ? props?.editData?.type : "",
    address_line1: props?.editData?.address_line1 ?? "",
    address_line2: props?.editData?.address_line2 ?? "",
    address_line3: props?.editData?.address_line3 ?? "",
    city: props?.editData?.city ?? "",
    district: props?.editData?.district ?? "",
    state: props?.editData?.state ?? "",
    country: props?.editData?.country ?? "",
    pincode: props?.editData?.pincode ?? "",
    google_location_data: props?.editData?.google_location_data ?? "",
    errorValidation: {
      error: {
        address_line1: false,
        use: false,
        type: false,
        address_line2: false,
        address_line3: false,
        city: false,
        district: false,
        state: false,
        country: false,
        pincode: false,
        google_location_data: false,
      },
    },
  });

  const onChange = (data) => {
    setState({ ...state, use: data });
  };

  const onChangeType = (data) => {
    setState({ ...state, type: data });
  };

  const onChangeInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const validation = () => {
    let isValidaddress1 =
      state?.address_line1?.trim().length === 0 ? false : true;

    // let isValiduse = (Object.keys(state?.use)?.length === 0 && (state?.use !== null || undefined )) ? false : true;
    let isValidtype = state?.type?.trim().length === 0 ? false : true;
    let isValidaddress2 =
      state?.address_line2?.trim().length === 0 ? false : true;
    let isValidaddress3 =
      state?.address_line3?.trim().length === 0 ? false : true;
    let isValidcity = state?.city?.trim().length === 0 ? false : true;
    let isValiddistrict = state?.district?.trim().length === 0 ? false : true;
    let isValidstate = state?.state?.trim().length === 0 ? false : true;
    let isValidcountry = state?.country?.trim().length === 0 ? false : true;
    let isValidpincode = state?.pincode?.trim().length === 0 ? false : true;
    // let isValidlocation = state?.google_location_data?.length === 0 ? false : true;

    if (
      isValidtype &&
      isValidaddress1 &&
      isValidaddress2 &&
      isValidaddress3 &&
      // && isValidlocation
      isValidcity &&
      isValiddistrict &&
      isValidstate &&
      isValidcountry &&
      isValidpincode
    ) {
      return true;
    } else {
      state.errorValidation.error = {
        address_line1: state?.address_line1?.trim().length === 0 ? true : false,
        type: state?.type?.trim().length === 0 ? true : false,
        // use: Object.keys(state?.use)?.length === 0 ? false : true,
        // type: Object.keys(state?.type)?.length === 0 ? false : true,
        address_line2: state?.address_line2?.trim().length === 0 ? true : false,
        address_line3: state?.address_line3?.trim().length === 0 ? true : false,
        city: state?.city?.trim().length === 0 ? true : false,
        district: state?.district?.trim().length === 0 ? true : false,
        state: state?.state?.trim().length === 0 ? true : false,
        country: state?.country?.trim().length === 0 ? true : false,
        pincode: state?.pincode?.trim().length === 0 ? true : false,
        google_location_data:
          state?.google_location_data?.length === 0 ? true : false,
      };
      setState({ ...state });
    }
  };

  const submitForm = () => {
    const isValidated = validation();

    if (!isValidated) {
      return false;
    }

    props.addAddress(state, props.isEdit, index);
  };
  return (
    <div className={classes.root}>
      <Grid
        item
        xs={12}
        style={{ height: "52px", display: "flex", alignItems: "center" }}
      >
        <Typography className={classes.header}>
          {props.isEdit ? "Update Address" : "Add Address"}
        </Typography>
      </Grid>
      <Divider />

      <Grid
        container
        style={{ height: "calc(100% - 105px)", overflowY: "auto" }}
      >
        {/* <Grid item xs={12} className={classes.formNameParent}>
          <Select
            name="use"
            error={state.errorValidation.error.use}
            helperText={
              state.errorValidation.error.use === true
                ? Message.requiredMessage
                : ""
            }
            label={"Use"}
            id="manageclienticdcode"
            placeholder={"use"}
            value={state?.use ? state?.use?.value : []}
            handleselect={(data) => onChange(data)}
            option={[]}
          />
        </Grid> */}
        <Grid item xs={12} className={classes.formNameParent}>
          <MuiSelect
            name="Type"
            error={state.errorValidation.error.type}
            helperText={state.errorValidation.error.type === true ? "ig" : ""}
            label={"Type"}
            isrequired
            id="manageclientitype"
            placeholder={"Select"}
            value={Use?.data?.find((l) => l?.coding?._id === state?.type)}
            getOptionLabel={(option) => option?.coding?.display ?? {}}
            handleselect={(data) => onChangeType(data?.coding?._id)}
            option={Use?.data ?? []}
          />
        </Grid>
        {/*
                <Grid item xs={12} className={classes.formNameParent}>
                    <Location
                        id={"Location"}
                        value={state.google_location_data}
                        label={"Location"}
                        handleChange={(value) => setState({ ...state, google_location_data: value })}
                        isrequired={state.errorValidation.error.google_location_data}
                        errorValidation={state.errorValidation.error.google_location_data ?? {}}
                        helperText={
                            state.errorValidation.error.google_location_data === true ? Message.requiredMessage : ''
                        }
                    />
                </Grid> */}

        <Grid item xs={12} className={classes.formNameParent}>
          <Typography variant="subtitle2">
            Address Line 1
            <span style={{ color: "red", marginLeft: "2px" }}>*</span>
          </Typography>

          <TextField
            onChange={(e) => onChangeInput(e)}
            name="address_line1"
            value={state?.address_line1 ?? ""}
            fullWidth
            placeholder="Add Address"
            id="manageusername_ippc"
            variant="outlined"
            size="small"
            margin="dense"
            error={state.errorValidation.error.address_line1}
            helperText={
              state.errorValidation.error.address_line1 === true
                ? Message.requiredMessage
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} className={classes.formNameParent}>
          <Typography variant="subtitle2">
            Address Line 2
            <span style={{ color: "red", marginLeft: "2px" }}>*</span>
          </Typography>
          <TextField
            onChange={(e) => onChangeInput(e)}
            name="address_line2"
            value={state?.address_line2 ?? ""}
            fullWidth
            placeholder="Add Address"
            id="manageusername_ippc"
            variant="outlined"
            size="small"
            margin="dense"
            error={state.errorValidation.error.address_line2}
            helperText={
              state.errorValidation.error.address_line2 === true
                ? Message.requiredMessage
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} className={classes.formNameParent}>
          <Typography variant="subtitle2">
            Address Line 3
            <span style={{ color: "red", marginLeft: "2px" }}>*</span>
          </Typography>
          <TextField
            onChange={(e) => onChangeInput(e)}
            name="address_line3"
            value={state?.address_line3 ?? ""}
            fullWidth
            placeholder="Add Address"
            id="manageusername_ippc"
            variant="outlined"
            size="small"
            margin="dense"
            error={state.errorValidation.error.address_line3}
            helperText={
              state.errorValidation.error.address_line3 === true
                ? Message.requiredMessage
                : ""
            }
          />
        </Grid>

        <Grid item xs={12} className={classes.formNameParent}>
          <Typography variant="subtitle2">
            City
            <span style={{ color: "red", marginLeft: "2px" }}>*</span>
          </Typography>
          <TextField
            onChange={(e) => {
              const re = /^[a-zA-Z\s]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                onChangeInput(e);
              }
            }}
            name="city"
            value={state?.city ?? ""}
            fullWidth
            placeholder="Add city"
            id="city"
            variant="outlined"
            size="small"
            margin="dense"
            error={state.errorValidation.error.city}
            helperText={
              state.errorValidation.error.city === true
                ? Message.requiredMessage
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} className={classes.formNameParent}>
          <Typography variant="subtitle2">
            District
            <span style={{ color: "red", marginLeft: "2px" }}>*</span>
          </Typography>
          <TextField
            onChange={(e) => {
              const re = /^[a-zA-Z\s]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                onChangeInput(e);
              }
            }}
            name="district"
            value={state?.district ?? ""}
            fullWidth
            placeholder="Add district"
            id="district"
            variant="outlined"
            size="small"
            margin="dense"
            error={state.errorValidation.error.district}
            helperText={
              state.errorValidation.error.district === true
                ? Message.requiredMessage
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} className={classes.formNameParent}>
          <Typography variant="subtitle2">
            State
            <span style={{ color: "red", marginLeft: "2px" }}>*</span>
          </Typography>
          <TextField
            onChange={(e) => {
              const re = /^[a-zA-Z\s]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                onChangeInput(e);
              }
            }}
            name="state"
            value={state?.state ?? ""}
            fullWidth
            placeholder="Add state"
            id="state"
            variant="outlined"
            size="small"
            margin="dense"
            error={state.errorValidation.error.state}
            helperText={
              state.errorValidation.error.state === true
                ? Message.requiredMessage
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} className={classes.formNameParent}>
          <Typography variant="subtitle2">
            Country
            <span style={{ color: "red", marginLeft: "2px" }}>*</span>
          </Typography>
          <TextField
            onChange={(e) => {
              const re = /^[a-zA-Z\s]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                onChangeInput(e);
              }
            }}
            name="country"
            value={state?.country ?? ""}
            fullWidth
            placeholder="Add country"
            id="country"
            variant="outlined"
            size="small"
            margin="dense"
            error={state.errorValidation.error.country}
            helperText={
              state.errorValidation.error.country === true
                ? Message.requiredMessage
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} className={classes.formNameParent}>
          <Typography variant="subtitle2">
            Pincode
            <span style={{ color: "red", marginLeft: "2px" }}>*</span>
          </Typography>
          <TextField
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                onChangeInput(e);
              }
            }}
            name="pincode"
            value={state?.pincode ?? ""}
            fullWidth
            placeholder="Add Pincode"
            id="pincode"
            variant="outlined"
            size="small"
            margin="dense"
            error={state.errorValidation.error.pincode}
            helperText={
              state.errorValidation.error.pincode === true
                ? Message.requiredMessage
                : ""
            }
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid item className={classes.btnParent}>
        <Button
          size="large"
          variant="outlined"
          color="primary"
          className={classes.marginR}
          onClick={() => props.onDrawerClosed()}
          id="addAndUpdateAddressButton"
        >
          {"Cancel"}
        </Button>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={() => submitForm()}
          id="addAndUpdateAddressButton"
        >
          {"Save"}
        </Button>
      </Grid>
    </div>
  );
};
