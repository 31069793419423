import React from 'react'
import { SvgIcon } from '@material-ui/core'



export function Organization(props) {

    return (
        <SvgIcon viewBox="0 0 9.62 14.921" style={{ fontSize: '1.25rem', marginRight: "8px" }}>
            <path d="M5.334,3V9.224H6.89V7.668h.778V6.112H6.89V4.556H8.446V6.112H10V4.556h1.556V6.112h1.556V3Zm3.89,4.668V17h1.556V15.447h1.556V17h1.556V15.447h1.556V17H17V7.668Zm1.556,1.556h1.556v1.556H10.779Zm3.112,0h1.556v1.556H13.891ZM3,10.779V17H4.556V15.447H6.112V17H7.668V10.779Zm1.556,1.556H6.112v1.556H4.556Zm6.224,0h1.556v1.556H10.779Zm3.112,0h1.556v1.556H13.891Z" transform="translate(-5 -3)" />
        </SvgIcon>
    )
}