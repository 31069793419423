/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-01-28
 * @modify date 2021-06-22
 * @desc Form Tree
 */

import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { TreeItem, TreeView } from "@material-ui/lab";
import { Typography, makeStyles } from "@material-ui/core";

import { AlertContext } from "../../contexts";
import { AlertProps } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 400,
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: 6,
    justifyContent: "space-between",
  },
  activeTreeSection: {
    // backgroundColor: 'rgba(63, 81, 181, 0.08)',
    display: "flex",
    alignItems: "center",
    padding: 6,
    justifyContent: "space-between",
    // color: theme.palette.primary.dark,
    // borderLeft: '3px solid ' + theme.palette.primary.light,
    borderRadius: 4,
  },
  labelText: {
    overflowWrap: "anywhere",
  },
  emptyForm: {
    marginTop: "34vh",
    alignItems: "center",
    // marginLeft: theme.spacing(4)
  },
  list: {
    paddingTop: 4,
    "& .MuiTreeItem-label": {
      paddingLeft: 0,
      // borderRadius: theme.spacing(1)
    },
  },
  blocklist: {
    paddingTop: 4,
    "& .MuiTreeItem-label": {
      paddingLeft: 0,
      // borderRadius: theme.spacing(1),
      // background:"red"
    },
  },
  badge: {
    // marginLeft: theme.spacing(1),
    padding: "0px 8px",
    // background: theme.palette.common.white,
    // color: theme.palette.ternary.main,
    fontFamily: "ippc_extrabold !important",
    // border: `0.2px solid ${theme.palette.ternary.main}`,
    borderRadius: 12,
  },
}));

export const FormTreeView = ({
  tree_view,
  getSectionId,
  selected_section_id,
  selected_form = {},
  answer = {},
  view,
  historyOfViewedForms,
  setHistoryOfViewedForms,
  isReadyOnly,
  isEdit,
}) => {
  const classes = useStyles();
  const alert = React.useContext(AlertContext);

  const [expanded, setExpanded] = React.useState(selected_form?.expanded ?? []);
  const [selected, setSelected] = React.useState([selected_form]);

  React.useEffect(() => {
    setExpanded(selected_form?.expanded);
    setSelected([selected_section_id]);
  }, [selected_form, selected_section_id]);

  React.useEffect(() => {
    if (view?.length === 0) {
      setHistoryOfViewedForms([]);
    }
    // eslint-disable-next-line
  }, [view]);

  const fillFormAlert = () => {
    alert.setSnack({
      ...alert,
      open: true,
      severity: AlertProps.severity.warning,
      msg: "Please Fill and Save the Previous Form To continue !",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.center,
    });
  };

  const canIableToClick = (node) => {
    if (node?.dependent) {
      let valueArr = node?.dependent?.option?.map((_) => _.value) ?? [];

      if (
        valueArr.indexOf(answer[node?.dependent?.parent_question_id]?.answer) >
        -1
      ) {
        return true;
      }

      return false;
    }
    return true;
  };
  const isRead = isReadyOnly;

  let isAlreadyVisted = historyOfViewedForms?.map((item) => {
    return item[1]?.id;
  });

  const renderTree = (nodes) => {
    let isAllow = isAlreadyVisted?.includes(nodes?.id);

    if (
      !isAllow &&
      nodes?.id !== view[1]?.id &&
      nodes.is_investigator_section !== true &&
      !isRead &&
      !isEdit
    ) {
      return (
        <TreeItem
          key={nodes.id}
          collapseIcon={
            <ExpandMoreIcon id={`${canIableToClick(nodes)}`} color="primary" />
          }
          expandIcon={
            <ChevronRightIcon
              id={`${canIableToClick(nodes)}`}
              color="primary"
            />
          }
          nodeId={nodes.id}
          label={label_type(nodes)}
          id={`${canIableToClick(nodes)}`}
          onClick={fillFormAlert}
          className={classes.blocklist}
        >
          {Array.isArray(nodes.child_sections) &&
          nodes?.child_sections?.length > 0 ? (
            <Typography component="h6" className={classes.sublist}>
              {nodes.child_sections.map((node) => renderTree(node))}
            </Typography>
          ) : null}
        </TreeItem>
      );
    } else {
      return (
        <TreeItem
          key={nodes.id}
          collapseIcon={
            <ExpandMoreIcon id={`${canIableToClick(nodes)}`} color="primary" />
          }
          expandIcon={
            <ChevronRightIcon
              id={`${canIableToClick(nodes)}`}
              color="primary"
            />
          }
          nodeId={nodes.id}
          label={label_type(nodes)}
          id={`${canIableToClick(nodes)}`}
          onClick={() => onClickSection(nodes)}
          className={classes.list}
        >
          {Array.isArray(nodes.child_sections) &&
          nodes?.child_sections?.length > 0 ? (
            <Typography component="h6" className={classes.sublist}>
              {nodes.child_sections.map((node) => renderTree(node))}
            </Typography>
          ) : null}
        </TreeItem>
      );
    }
  };

  const label_type = (menu) => {
    const type = menu?.is_form ? "Form" : "Section";

    const is_section_type = menu?.section_type;

    return (
      <div
        id={`${canIableToClick(menu)}`}
        className={
          selected_section_id === menu.id
            ? classes.activeTreeSection
            : classes.labelRoot
        }
      >
        {is_section_type >= 0 && (
          <>
            {type === "Section" && (
              <>
                <Typography
                  id={`${canIableToClick(menu)}`}
                  variant={"body2"}
                  color={
                    canIableToClick(menu) ? "textPrimary" : "textSecondary"
                  }
                  className={classes.labelText}
                >
                  {menu?.section_name}
                  {is_section_type === 0 && " (" + type + ")"}
                  {is_section_type > 0 && (
                    <Typography
                      id={`${canIableToClick(menu)}`}
                      variant="caption"
                      className={classes.badge}
                      component="span"
                    >
                      {is_section_type === 1
                        ? "Verification"
                        : is_section_type === 2
                        ? "Registration"
                        : "Investigation"}
                    </Typography>
                  )}
                </Typography>
              </>
            )}

            {type === "Form" && (
              <Typography
                id={`${canIableToClick(menu)}`}
                variant={"body2"}
                color={canIableToClick(menu) ? "textPrimary" : "textSecondary"}
                className={classes.labelText}
              >
                {menu?.section_name + " (" + type + ")"}
              </Typography>
            )}
          </>
        )}
      </div>
    );
  };

  const onClickSection = (data) => {
    //
    if (data.is_form && canIableToClick(data)) {
      getSectionId(data);
    }
  };

  const handleToggle = (event, nodeIds) => {
    //
    if (event.target.id === "true") {
      setExpanded(nodeIds);
    }
  };

  const handleSelect = (event, nodeIds) => {
    //
    if (event.target.id === "true") {
      setSelected(nodeIds);
    }
  };

  return (
    <div>
      {tree_view.map((_, key) => (
        <TreeView
          key={key}
          className={classes.root}
          defaultCollapseIcon={<ExpandMoreIcon color="primary" />}
          defaultExpandIcon={<ChevronRightIcon color="primary" />}
          expanded={expanded}
          selected={selected}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
          defaultExpanded={selected_form.expanded}
          defaultSelected={selected_form.id}
        >
          {renderTree(_)}
        </TreeView>
      ))}

      {tree_view?.length === 0 && (
        <Typography
          variant="subtitle2"
          color="textSecondary"
          className={classes.emptyForm}
        >
          {"Section/Form not added yet."}
        </Typography>
      )}
    </div>
  );
};
