import React from "react";
import {
  Button,
  makeStyles,
  Divider,
  Typography,
  Grid,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { AlertContext, BackdropContext } from "../../contexts";
import Select from "../select";
// store
import { actions } from "surveillance-binder";
import { useSelector, useDispatch } from "react-redux";
import { AlertProps, Message } from "../../utils";

// Store Action's list
const { AddDisease } = actions;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  header: {
    padding: "10px 20px",
    color: theme.palette.primary.main,
  },
  countryParent: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  countryCodeParent: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  btnParent: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  btn: {
    alignItems: "center",
    // justifyContent:'center'
  },
}));

export const AddEditDisease = (props) => {
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);

  const classes = useStyles();

  const onChange = (data) => {
    setState({ ...state, icd10: data });
  };

  const onChangeClass = (data) => {
    setState({ ...state, classification: data });
  };

  const valuelabelfinder = (data) => {
    try {
      const result = data.map((v) => {
        let obj = {
          label: v?.coding?.[0]?.display,
          value: v?.coding?.[0]?.id,
          _id: v?.coding?.[0]?._id,
        };
        return obj;
      });
      return result;
    } catch (err) {
      return [];
    }
  };

  const editvaluelabelfinder = (data) => {
    try {
      const result = data.map((v) => {
        let obj = {
          label: v?.display,
          value: v?.id,
          _id: v?._id,
        };
        return obj;
      });
      return result;
    } catch (err) {
      return [];
    }
  };

  const [state, setState] = React.useState({
    name: props?.editData?.disease_name ?? "",
    // id: props?.editData?.disease_id ?? "",
    icd10: editvaluelabelfinder(props?.editData?.icd_10) ?? [],
    classification: props?.editData?.classification
      ? {
          label: props?.editData?.classification?.display,
          value: props?.editData?.classification?.id,
          _id: props?.editData?.classification?._id,
        }
      : {},
    errorValidation: {
      error: {
        name: false,
        icd10: false,
        classification: false,
      },
    },
  });

  // Declaring Store dispatch
  const dispatch = useDispatch();

  const upsertDisease = false;
  useSelector((state) => state?.diseaseSlice?.upsertDisease);

  const onChangeInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const validation = () => {
    let isValidname = state?.name?.trim().length === 0 ? false : true;
    let isValidicd10 = state?.icd10?.length === 0 ? false : true;
    let isValidclass =
      state?.classification === null ||
      Object.keys(state?.classification).length === 0
        ? false
        : true;

    if (isValidname && isValidicd10 && isValidclass) {
      return true;
    } else {
      state.errorValidation.error = {
        name: state?.name?.trim().length === 0 ? true : false,
        icd10: state?.icd10?.length === 0 ? true : false,
        classification:
          state?.classification === null ||
          Object.keys(state?.classification).length === 0
            ? true
            : false,
      };
      setState({ ...state });
    }
  };

  const idextrator = (data) => {
    const result = data.map((v) => v._id);
    return result;
  };
  const submitForm = () => {
    const isValidated = validation();

    if (!isValidated) {
      return false;
    }
    let __params = {
      disease_name: state?.name,
      // disease_id: state?.id,
      classification: state.classification._id,
      icd_10: idextrator(state.icd10),
      // isEdit: props.isEdit,
    };
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: props.isEdit ? Message.disease_update : Message.disease_add,
    });
    let apidata = {
      dbname: process.env.REACT_APP_DB,
      entity: "master_disease",
      ismeta: true,
      metadataid: process.env.REACT_APP_METADATAID,
      metadatadbname: process.env.REACT_APP_METADATA_DB_NAME,
      filter: props.isEdit ? { _id: `${props?.editData?._id}` } : undefined,
      doc: __params,
    };

    Promise.resolve(dispatch(AddDisease(apidata))).then((res) => {
      backdrop.setBackDrop({ ...backdrop, open: false });
      props.onActionCompleted(props.isEdit);
      let resStatus = res.payload.error;
      let statusMessage = props.isEdit
        ? "Disease Updated Successfully !"
        : "Disease Added Successfully !";
      let statusMessage2 = props.isEdit
        ? "Disease Updated Failed!"
        : "Disease Adding Failed !";
      if (res?.payload?.message === "Disease Already Exist !") {
        alert.setSnack({
          ...alert,
          open: true,
          severity: resStatus
            ? AlertProps.severity.error
            : AlertProps.severity.success,
          msg: res?.payload?.message,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: resStatus
            ? AlertProps.severity.error
            : AlertProps.severity.success,
          msg: resStatus ? statusMessage2 : statusMessage,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
    });
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        <Grid item>
          <Typography className={classes.header}>
            {props?.isEdit ? "Update Disease" : "Add Disease"}
          </Typography>
          <Divider />
        </Grid>

        <Grid item className={classes.countryParent}>
          <TextField
            onChange={(e) => onChangeInput(e)}
            name="name"
            value={state?.name ?? ""}
            fullWidth
            label="Disease Name"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            error={state.errorValidation.error.name}
            helperText={
              state.errorValidation.error.name === true
                ? Message.requiredMessage
                : ""
            }
          />
        </Grid>

        <Grid item className={classes.countryParent}>
          <Select
            name="icd10"
            error={state?.errorValidation?.error.icd10}
            helperText={
              state?.errorValidation?.error?.icd10 === true
                ? Message?.requiredMessage
                : ""
            }
            label={"ICD 10 code"}
            id="managediseaseicdcode"
            multiple
            value={state?.icd10 ? state?.icd10 : []}
            handleselect={(data) => onChange(data)}
            // option={icd10Array}
            option={valuelabelfinder(props?.data)}
          />
        </Grid>

        <Grid item className={classes.countryParent}>
          <Select
            name="classification"
            error={state?.errorValidation?.error?.classification}
            helperText={
              state?.errorValidation?.error?.classification === true
                ? Message?.requiredMessage
                : ""
            }
            label={"Classification"}
            id="managediseaseClassification"
            value={state?.classification ? [state?.classification] : []}
            handleselect={(data) => onChangeClass(data)}
            // option={classificationArray}

            option={valuelabelfinder(props?.dataClass)}
          />
        </Grid>

        <Grid item className={classes.btnParent}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            disabled={upsertDisease?.loading}
            onClick={() => submitForm()}
            id="addAndUpdateDiseaseButton"
          >
            {props?.isEdit
              ? upsertDisease?.loading
                ? "Updating..."
                : "Update Disease"
              : upsertDisease?.loading
              ? "Adding..."
              : "Add Disease"}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
