import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { LoadingScreen } from "../../../components/loadingScreen";
import {
  AxiosCall,
  GetLastSevenDays,
  Months,
  stringToColor,
} from "../../../utils";
import BarChart from "./barChart";
import DoughnutChart from "./doughnutChart";
import { ToggleButtonComponentV2 } from "./toggleButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    padding: theme.spacing(2),
    width: "100%",
    height: "100%",
  },
  CardRoot: {
    height: "100%",
  },
  CardHeader: {
    padding: "8px 16px",
    "&& .MuiCardHeader-content": {
      fontSize: "1rem",
    },
    "&& .MuiCardHeader-action": {
      marginTop: "0",
      marginRight: "0",
    },
  },
  CardFooter: {
    padding: "16px",
    height: "64px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  CardContent: {
    padding: "16px",

    "&:last-child": {
      paddingBottom: "32px",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: theme.spacing(1),
  },
}));

const Chart = (props) => {
  const {
    title = null,
    uniqueId = null,
    filterKey = null,
    monthlyQueryId,
    dateQueryId,
    gridItem,
  } = props;
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);

  const [value, setValue] = React.useState("Monthly");

  const [data, setData] = React.useState();
  const [result, setResult] = React.useState([]);

  React.useLayoutEffect(() => {
    if (uniqueId) {
      getNotificationMonthly(uniqueId, "Monthly", monthlyQueryId);
      setValue("Monthly");
    } else {
      setLoading(false);
    }
  }, [uniqueId]);

  const getDateFormTo = (value) => {
    // debugger;
    if (value === "Monthly") {
      let date = new Date();
      let firstday = new Date(date.getFullYear(), 0, 1);
      let formDate = Date.parse(firstday) / 1000;
      let lastday = new Date(date.getFullYear(), 12, 0);
      let toDate = Date.parse(lastday) / 1000;

      return {
        formDate,
        toDate,
      };
    } else if (value === "Weekly") {
      let curr = new Date();
      let seventh = new Date(curr);

      let firstday = new Date(seventh.setDate(curr.getDate() - 6));
      // var lastday = new Date(curr.setDate(last));
      let formDate = Date.parse(firstday) / 1000;
      let toDate = Date.parse(curr) / 1000;

      return {
        formDate,
        toDate,
      };
    } else if (value === "Daily") {
      // let date = new Date();
      const startOfDay = new Date();
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = new Date();
      endOfDay.setHours(23, 59, 59, 999);
      let formDate = Date.parse(startOfDay) / 1000;
      let toDate = Date.parse(endOfDay) / 1000;
      return {
        formDate,
        toDate,
      };
    }
  };

  const getNotificationMonthly = async (uniqueId, val, QueryId) => {
    try {
      setLoading(true);

      let date = getDateFormTo(val);

      let params = {
        db_name: process.env.REACT_APP_DB,
        filter: {
          [filterKey]: uniqueId,
          from: date.formDate,
          to: date.toDate,
        },
        queryid: QueryId,
      };
      let res = await AxiosCall(
        "post",
        `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
        params
      );
      if(res?.collection){
        let result = res?.collection;
      if (result?.length > 0) {
        setResult(result);

        if (val === "Daily") {
          let constructDataSet = {
            name: "NOTIFIER",
            labels: result.map((l) => l.Status),
            datasets: [
              {
                label: "Status",
                data: result.map((l) => l.Count),
                backgroundColor: result.map((l) =>
                  stringToColor(`${l.Status}blue`)
                ),
                borderWidth: result.length > 1 ? 8 : 0,
                borderColor: "#FFFFFF",
                hoverBorderColor: "#FFFFFF",
                hoverOffset: result.length > 1 ? 8 : 0,
              },
            ],
          };
          setData(constructDataSet);
          setLoading(false);
        } else if (val === "Monthly") {
          let getDataSet = getDataSetForMonth(result);
          setData(getDataSet);
          setLoading(false);
        } else {
          let getDataSet = getDataSetByDate(result);
          setData(getDataSet);
          setLoading(false);
        }
      } else {
        setLoading(false);

        setResult([]);
      }
      }else {
        let result = res;
      if (result?.length > 0) {
        setResult(result);

        if (val === "Daily") {
          let constructDataSet = {
            name: "NOTIFIER",
            labels: result.map((l) => l.Status),
            datasets: [
              {
                label: "Status",
                data: result.map((l) => l.Count),
                backgroundColor: result.map((l) =>
                  stringToColor(`${l.Status}blue`)
                ),
                borderWidth: result.length > 1 ? 8 : 0,
                borderColor: "#FFFFFF",
                hoverBorderColor: "#FFFFFF",
                hoverOffset: result.length > 1 ? 8 : 0,
              },
            ],
          };
          setData(constructDataSet);
          setLoading(false);
        } else if (val === "Monthly") {
          let getDataSet = getDataSetForMonth(result);
          setData(getDataSet);
          setLoading(false);
        } else {
          let getDataSet = getDataSetByDate(result);
          setData(getDataSet);
          setLoading(false);
        }
      } else {
        setLoading(false);

        setResult([]);
      }
      }
      
    } catch (error) {}
  };

  const getDataSetByDate = (data) => {
    let dates = GetLastSevenDays();
    let groupBy = data.reduce((pre, cu) => {
      let status = [...(pre[cu.Status] || []), cu];
      pre[cu.Status] = status;
      return pre;
    }, {});
    let getKey = Object.keys(groupBy);

    getKey.forEach((key) => {
      let temp = groupBy[key];
      let makeDays = dates.map((l) => {
        let value = temp.find((a) => a.DAY === l.value);
        if (value) {
          return {
            ...value,
            DAY: l.label,
          };
        } else {
          return {
            DAY: l.label,
            Count: 0,
            Status: key,
          };
        }
      });

      groupBy[key] = makeDays;
    });

    let constructDataSet = getKey.map((l) => {
      return {
        backgroundColor: stringToColor(`${l}blue`),
        barPercentage: 0.5,
        barThickness: 12,
        borderRadius: 4,
        categoryPercentage: 0.5,
        data: groupBy[l].map((l) => +l.Count),
        label: l,
        maxBarThickness: 10,
      };
    });

    let val = {
      datasets: [...constructDataSet],
      labels: dates.map((l) => l.label),
    };
    return val;
  };

  const getDataSetForMonth = (data) => {
    const months = Months();
    const groupBy = data.reduce((pre, val) => {
      let Status = [...(pre[val.Status] || []), val];
      pre[val.Status] = Status;
      return pre;
    }, {});
    let getKey = Object.keys(groupBy);
    getKey.forEach((v) => {
      let temp = groupBy[v];
      let makeMonth = months.map((l, i) => {
        let value = temp.find((a) => a.Month === i + 1);
        if (value) {
          return {
            ...value,
            Month: l,
          };
        } else {
          return {
            Month: l,
            Count: 0,
            Status: v,
          };
        }
      });
      groupBy[v] = makeMonth;
    });
    // console.log(groupBy);
    let constructDataSet = getKey.map((l) => {
      return {
        backgroundColor: stringToColor(`${l}blue`),
        barPercentage: 0.5,
        barThickness: 12,
        borderRadius: 4,
        categoryPercentage: 0.5,
        data: groupBy[l].map((l) => +l.Count),
        label: l,
        maxBarThickness: 10,
      };
    });

    let val = {
      datasets: [...constructDataSet],
      labels: months.map((l) => l),
    };
    return val;
  };

  const HandleFormat = async (val) => {
    if (val) {
      setValue(val);
      if (val === "Monthly") {
        getNotificationMonthly(uniqueId, val, monthlyQueryId);
      } else {
        getNotificationMonthly(uniqueId, val, dateQueryId);
      }
    }
  };

  return (
    <>
      <Card className={classes.CardRoot} {...props}>
        <CardHeader
          className={classes.CardHeader}
          action={
            <ToggleButtonComponentV2
              isSelected={value}
              onSelected={(val) => HandleFormat(val)}
              toggleButtonData={[
                { value: "Monthly", label: "Monthly" },
                { value: "Weekly", label: "Last 7 Days" },
                { value: "Daily", label: "Daily" },
              ]}
            />
          }
          title={`${title} Dashboard`}
        />
        <Divider />
        <CardContent className={classes.CardContent}>
          {loading ? (
            <div style={{ height: "300px" }}>
              <LoadingScreen />
            </div>
          ) : (
            <>
              {value === "Daily" && result?.length > 0 && (
                <DoughnutChart gridItem={gridItem} dataSet={data} />
              )}
              {value === "Monthly" && result?.length > 0 && (
                <BarChart dataSet={data} />
              )}
              {value === "Weekly" && result?.length > 0 && (
                <BarChart dataSet={data} />
              )}
              {result?.length === 0 && (
                <div
                  style={{
                    width: "100%",
                    height: "400px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    align="center"
                  >
                    No Record Found !
                  </Typography>
                </div>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default Chart;
