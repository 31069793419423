/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc All the environment variables are taken from process.env and exported
 * via Config variable.
 */

import dotenv from "dotenv";
dotenv.config();

let Config = {};

Config.api_url = process.env.REACT_APP_API_ENDPOINT;
Config.graphql = process.env.REACT_APP_GQL_ENDPOINT;
Config.socket = process.env.REACT_APP_SOCKET;
Config.project_name = process.env.PROJECT_NAME;
Config.project_Version = process.env.Version;
Config.dbname = process.env.REACT_APP_DB;

Config.taskanddocument = `${process.env.REACT_APP_TASKANDDOCUMENT}/?metadata_id=${process.env.REACT_APP_TASKANDDOCUMENT_METAID}&ismicrofrontend=true`;

export default Config;
