/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc Different routes and their corresponding component are defined here.
 */

import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Routes } from "./routes";

import {
  NotFound,
  Login,
  AccessManagement,
  Generalmaster,
  Reportviewer,
  Dashboard,
  ManageFacility,
  CreateFacility,
  ManageDisease,
  ManageLevel,
  TaskAndDocument,
  TaskViewers,
  Client,
  AddEditClientParent,
  ManageForms,
  FormConfigurator,
  Notify,
  NotificationDetail,
  FormRenderer,
  NotificationsV2,
  ViewOrganization,
  ViewLevel,
  NotifierReport,
  VerifierReport,
  ClientReport,
  DateReport,
} from "./../screens";

const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        <Route exact path={Routes.home} component={Login} />
        <Route path={Routes.login} component={Login} />
        <Route path={Routes.dashboard} component={Dashboard} />
        <Route path={Routes.AccessManagement} component={AccessManagement} />
        <Route path={Routes.Client} component={Client} />
        <Route path={Routes.CreateClient} component={AddEditClientParent} />
        <Route path={Routes.GeneralMaster} component={Generalmaster} />
        {/* <Route path={Routes.FormRenderer} component={Formrenderer} /> */}
        {/* <Route path={Routes.managedisease} component={ManageLevel} /> */}
        {/* <Route path={Routes.FormRenderer} component={Formrenderer} /> */}
        {/* <Route path={Routes.Managefacility} component={ManageFacility} /> */}
        <Route path={Routes.managedisease} component={ManageDisease} />
        {/* <Route path={Routes.taskDocument} component={TaskAndDocument} /> */}
        {/* <Route path={"/ml"} component={ManageLevel} /> */}
        {/* <Route path={"/ml"} component={ManageLevel} /> */}
        <Route path={Routes.taskViewer} component={TaskViewers} />
        {/* <Route path={Routes.notifications} component={NotificationsV2} /> */}
        <Route path={Routes.manage_forms} component={ManageForms} />
        <Route path={Routes.notify} component={Notify} />
        <Route
          exact
          path={Routes.manage_form_detail}
          component={FormConfigurator}
        />
        <Route
          exact
          path={Routes.ViewOrganization}
          component={ViewOrganization}
        />
        <Route exact path={Routes.ViewLevel} component={ViewLevel} />
        <Route exact path={Routes.notifierReport} component={NotifierReport} />
        <Route exact path={Routes.verifiertReport} component={VerifierReport} />
        <Route exact path={Routes.clientReport} component={ClientReport} />
        <Route exact path={Routes.dateReport} component={DateReport} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
