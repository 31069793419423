import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { IconButton } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.dark,
    border: "1px solid white",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function MappingTable({
  rows = [],
  deleteMappingTableRow = () => false,
  orgData,
  mappedDiseaseData,
  mappedFormData,
}) {
  const classes = useStyles();

  // console.log("row", rows);

  const giveMeMappingDetailString = (row) => {
    return (
      <>
        <>{`Disease -----------------: ${
          row?.mappingTable?.disease?.name ?? " - "
        }`}</>
        <br />
        <>{`Form --------------------: ${
          row?.mappingTable?.clonedFormFrom?.name ?? " - "
        }`}</>
        <br />
      </>
    );
  };

  return (
    <TableContainer component={Paper} id="TableContainer">
      <Table
        id="Table"
        className={classes.table}
        size="small"
        aria-label="customized table"
      >
        <TableHead id="TableHead" style={{}}>
          <TableRow id="TableRow">
            <StyledTableCell width="78" id="Sno">
              Sl No
            </StyledTableCell>
            <StyledTableCell align="left" id="Disease">
              Disease
            </StyledTableCell>
            <StyledTableCell align="left" id="Form">
              Form
            </StyledTableCell>
            <StyledTableCell align="left" id="Form">
              Facility
            </StyledTableCell>
            <StyledTableCell align="left" id="Action">
              Action
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody id="TableBody">
          {rows.map((row, key) => (
            <StyledTableRow key={key} id={"styledTablerow" + key}>
              <StyledTableCell
                component="th"
                scope="row"
                id={"StyledTableCell" + key}
              >
                {key + 1}
              </StyledTableCell>
              {/* <StyledTableCell align="left" id={"countryLabel" + key}>{row?.country?.label ?? ""}</StyledTableCell>
                            <StyledTableCell align="left" id={"selectedlevel" + key}>{row?.selectedLevel?.label ?? ""}</StyledTableCell> */}
              <StyledTableCell align="left" id={"diseaseLabel" + key}>
                {row?.mappingTable?.disease?.name ?? ""}
              </StyledTableCell>
              <StyledTableCell align="left" id={"formLabel" + key}>
                {row?.mappingTable?.clonedFormFrom?.name}
              </StyledTableCell>
              <StyledTableCell align="left" id={"formLabel" + key}>
                {row?.mappingTable?.selectedOrg?.name ?? ""}
              </StyledTableCell>
              <StyledTableCell align="left" id={"deketeBtncell" + key}>
                <IconButton
                  style={{ color: "red" }}
                  id={"deleteBtn" + key}
                  onClick={() =>
                    deleteMappingTableRow(row, giveMeMappingDetailString(row))
                  }
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
