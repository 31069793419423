import React from "react";
import * as WebDataRocksReact from "react-webdatarocks";
import "webdatarocks/webdatarocks.css";
import { withNavBars } from "../../HOCs";
import jwt_decode from "jwt-decode";
import ReportTemplate from "../../components/reporttemplate";
import Dailogdatefilter from "../../components/reportdailogfilter";
import { AlertContext } from "../../contexts";
import { AlertProps } from "../../utils";

const ReportVerifier = () => {
  const pivot = React.useRef(null);
  const alert = React.useContext(AlertContext);
  const [open, setOpen] = React.useState(true);
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [showTo, setShowTo] = React.useState(new Date());
  const [showFilter, setShowFilter] = React.useState(false);
  const [showFrom, setShowFrom] = React.useState(new Date());
  let singleData = [];
  const singleLevel = (data) => {
    console.log("reportverifier:",data)

    for (let i = 0; i < data.length; i++) {
      let Result = {}
      let answer = data[i].formdetails[0].status.answers
      let answerLength = data[i]?.formdetails[0]?.status?.answers?.length


      Result["Form Entry ID"] = data[i].formdetails[0]?.length !== 0
        ? data[i].formdetails[0].formentryid
        : ""
      Result["Patient Name"] = data[i].patientDetails[0]?.length !== 0
        ? ` ${data[i]?.patientDetails[0]?.name?.hasOwnProperty("first_name")
          ? data[i]?.patientDetails[0]?.name?.first_name
          : "-"
        } ${data[i]?.patientDetails[0]?.name?.hasOwnProperty("middle_name")
          ? data[i]?.patientDetails[0]?.name?.middle_name
          : ""
        } ${data[i]?.patientDetails[0]?.name?.hasOwnProperty("last_name")
          ? data[i]?.patientDetails[0]?.name?.last_name
          : ""
        }`
        : ""
      Result["gender"] = data[i].patientDetails[0]?.length !== 0 &&
        data[i].patientDetails[0]?.hasOwnProperty("gender")
        ? data[i].patientDetails[0]?.gender
        : "-"

      Result["age"] = data[i].patientDetails[0]?.length !== 0 &&
        data[i].patientDetails[0]?.hasOwnProperty("age")
        ? data[i].patientDetails[0]?.age
        : "-"

      Result["mobile No"] = data[i].patientDetails[0]?.length !== 0 &&
        data[i]?.patientDetails[0]?.mobileno?.hasOwnProperty("number") &&
        data[i]?.patientDetails[0]?.mobileno?.hasOwnProperty("phoneCode")
        ? ` ${data[i]?.patientDetails[0]?.mobileno?.phoneCode}${data[i]?.patientDetails[0]?.mobileno?.number}`
        : "-"
      Result["Notifier Status"] = data[i].formdetails[0]?.length !== 0
        ? data[i].formdetails[0].status.Notifier_Status
        : ""

      Result["Verifier Status"] = data[i].formdetails[0]?.length !== 0
        ? data[i].formdetails[0].status.Verifier_Status
        : ""

         let obj ={}
         
        
      for (let j = 0; j < answer.length; j++) {
        if(answer[j]?.questionname == "Referred From Hospital")
        {
        obj[answer[j]?.questionname] = answer[j]?.answer?.answer?.value
      }

      else{
        obj[answer[j]?.questionname] = answer[j]?.answer?.answer
      }
        
        Result["Date of admission(dd/mm/yy)"] = obj["Date of admission(dd/mm/yy)"]
        Result["Referred From Hospital"] = obj["Referred From Hospital"]
        Result["Mode of arrival"] = obj["Mode of arrival"]
        Result["Triage Time  (Door In)  (hh:mm)"] = obj["Triage Time  (Door In)  (hh:mm)"]
        Result["Time of first ECG  (hh:mm)"] = obj["Time of first ECG  (hh:mm)"]
        Result["Thrombolytic Medication Administered"] = obj["Thrombolytic Medication Administered"]
        Result["Time of thrombolytic administration (hh:mm)"] = obj["Time of thrombolytic administration (hh:mm)"]
        Result["Time of 1ry PCI began (hh:mm)"] = obj["Time of 1ry PCI began (hh:mm)"]
      }
      
      obj = {}
      

      // IdType:
      //   data[i].patientDetails[0]?.length !== 0 &&
      //   data[i].patientDetails[0]?.hasOwnProperty("IdType")
      //     ? data[i].patientDetails[0]?.IdType
      //     : "-",
      // ID:
      //   data[i].patientDetails[0]?.length !== 0 &&
      //   data[i].patientDetails[0]?.hasOwnProperty("Id")
      //     ? data[i].patientDetails[0]?.Id
      //     : "-",

      singleData.push(Result);
    }

    return singleData;
  };
  const normalize = (datas) => {
    let data = [];
    datas.forEach((e, i) => {
      if (datas[i].formdetails.length <= 1) {
        data.push(datas[i]);
      } else {
        datas[i].formdetails.forEach((e, j) => {
          let patientDetail = [...datas[i].patientDetails];
          data.push({
            formdetails: [datas[i].formdetails[j]],
            patientDetails: patientDetail,
          });
        });
      }
    });
    return data;
  };
  function customizeToolbar(toolbar) {
    let tabs = toolbar.getTabs();
    toolbar.getTabs = function () {
      delete tabs[0];
      delete tabs[1];
      delete tabs[2];
      delete tabs[4];
      delete tabs[5];
      return tabs;
    };
  }

  const handleSubmit = async () => {
    if (validation()) {
      const personId = jwt_decode(localStorage.getItem("queue_token"));
      const myHeader = new Headers();
      myHeader.append("content-type", "application/json");
      const data = await fetch(
        process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES,
        {
          method: "post",
          headers: myHeader,
          body: JSON.stringify({
            db_name: process.env.REACT_APP_DBNAME,
            filter: {
              verifierId: personId?.personid,
              fromdate: Date.parse(fromDate) / 1000,
              enddate: Date.parse(toDate) / 1000,
            },
            queryid: process.env.REACT_APP_VERIFIER_QUERY,
          }),
        }
      );
      const result = await data.json();
      var report = {
        dataSource: {
          data: singleLevel(normalize(result.collection ? result.collection : result)),
        },
        options: {
          grid: {
            type: "flat",
            showGrandTotals: "off",
            showTotals: "off",
          },
        },
      };
      if (report.dataSource.data.length === 0) {
        pivot.current.webdatarocks.clear();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.warning,
          msg: "No Record",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else if (pivot.current?.webdatarocks !== null) {
        pivot.current.webdatarocks.setReport(report);
      }
      handleClose();
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Required Fields Are Empty",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const validation = () => {
    let valid = false;
    if (fromDate !== null && toDate !== null) return (valid = true);
    return valid;
  };

  return (
    <>
      <ReportTemplate
        showFilter={showFilter}
        showFrom={showFrom}
        showTo={showTo}
        setOpen={setOpen}
      />
      <WebDataRocksReact.Pivot
        ref={pivot}
        toolbar={true}
        width="100%"
        beforetoolbarcreated={customizeToolbar}
      />
      <Dailogdatefilter
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        handlechangefordate={() => false}
        open={open}
        setOpen={setOpen}
        setToDate={setToDate}
        setFromDate={setFromDate}
        setShowTo={setShowTo}
        setShowFrom={setShowFrom}
        state={{ open, toDate, fromDate }}
      />
    </>
    // </ReportTemplate>
  );
};

export default withNavBars(ReportVerifier);
